import { Injectable, OnDestroy } from '@angular/core';
import { PersonalisationPersonalData } from '@types';
import { Logger } from '@utils/logger';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { LDFlagSet, LDFlagValue } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvConfigService } from './env-config.service';

const logger = Logger.getLogger('LaunchDarklyService');

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService implements OnDestroy {
  private client: LaunchDarkly.LDClient;
  private flags: LDFlagSet = null;
  public isInitialised: boolean;

  private flagChange$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );

  constructor(private envConfigService: EnvConfigService) {}

  /**
   * initialize the launch darkly
   */
  public initialize(userData: PersonalisationPersonalData) {
    this.isInitialised = true;
    const user = {
      kind: 'user',
      key: userData.identifiers.globalId,
      type: userData.clientType,
    } as LaunchDarkly.LDContext;
    this.client = LaunchDarkly.initialize(
      this.envConfigService.getEnvConfig().launchDarklyKey,
      user
    );
  }

  /**
   * get all the flags
   */
  public getFlag$(
    flagKey: string,
    defaultValue: LDFlagValue
  ): Observable<LDFlagValue> {
    this.client
      .waitUntilReady()
      .then(() => {
        this.flags = this.client.allFlags();
        const flagsArray = Object.entries(this.flags);
        flagsArray.filter(([key, value]) => {
          if (key === flagKey) {
            this.setFlag(key, defaultValue);
            return value;
          } else {
            return defaultValue;
          }
        });
        this.client.on('initialized', (flags) => {
          this.flags = { ...flags };
        });

        this.client.on(`change:${flagKey}`, (flagValue) => {
          this.setFlag(flagKey, defaultValue);
        });
      })
      .catch((err) => {
        logger.error('launch darkly intialization error : ', err);
      });
    return this.flagChange$.asObservable();
  }

  /**
   * track the button click event in the launch darkly for experimentation
   */
  public trackClick(metricsName: string): void {
    this.client?.track(metricsName);
  }

  private setFlag(flagKey, defaultValue) {
    this.flagChange$.next(this.client.variation(flagKey, defaultValue));
  }

  async ngOnDestroy() {
    await this.client?.close();
  }
}
