import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  Card,
  CardState,
  FavoriteFundInfo,
  FavoriteFundTablePropertiesInfo,
  CardDashboardAccountsLinksInfo,
  CardDashboardAccountsData,
  FlyoutMenu,
  HypotheticalInfo,
  HypotheticalTablePropertiesInfo,
  LiteratureDocumentInfo,
  LiteratureTablePropertiesInfo,
  ModalService,
  NavigationService,
  RecordInfo,
  SimpleModalConfig,
  ToggleButton,
} from '@frk/eds-components';
import { EnvConfigService } from '@services/env-config.service';
import { ProfileService } from '@services/profile.service';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { MyDashboardService } from './my-dashboard.service';
import {
  AccountsAccess,
  IUserProfile,
  IUserProfileInfo,
} from '@services/profile.interface';
import get from 'lodash/get';
import { forkJoin, Observable, Subject } from 'rxjs';
import { filter, pluck, takeUntil } from 'rxjs/operators';
import { WINDOW } from '@ng-web-apis/common';
import { DEFAULT_IMAGE_SRC, FP, SH } from '@utils/app.constants';
import { DOCUMENT } from '@angular/common';
import {
  DashboardContentCard,
  DashboardLabels,
  DashboardParameters,
  FavouriteFund,
  AccountsOptions,
  Output,
  RepDetails,
  SalesTeamRootObject,
} from './my-dashboard.interface';
import { TranslateService } from '@shared/translate/translate.service';
import { MyliteratureService } from '@literature/literature-dashboard/services/myliterature.service';
import { CartHandlerService } from '@literature/services/cart-handler.service';
import {
  CartActionPerformed,
  ConfigurationId,
  FavoritesRequestDetail,
  FundShareClassId,
  LiteratureDocumentListingData,
  LiteratureDocumentListingState,
  LiteratureHistoryData,
  ModalId,
  OrderHistoryDetail,
  PersonalisationFavoriteData,
  ProductType,
} from '@types';
import { Logger } from '@utils/logger';
import {
  maskUsPhoneNo,
  replaceTokens,
  titleCase,
} from '@utils/text/string-utils';
import { FavoritesService } from '@products/services/favorites.service';
import { AppStateService } from '@services/app-state.service';
import { LinkService } from '@services/link.service';
import { WidenService } from '@services/widen.service';
import { getMenus } from '@utils/pagemodel-utils';
import { ViewModeService } from '@services/view-mode.service';
const logger = Logger.getLogger('MyDashboardComponent');
interface CardData {
  linkCollection: Array<any>;
  linkCompound: LinkCompound;
  link: LinkCollection;
  signInRequired: boolean;
}
interface LinkCompound {
  url: string;
  displayText: string;
  signInLink?: boolean;
  signInIntroTextLabel?: string;
}
interface LinkCollection {
  linkCollection: Array<any>;
}
@Component({
  selector: 'ft-my-dashboard',
  templateUrl: './my-dashboard.component.html',
  styleUrls: ['./my-dashboard.component.scss'],
})
export class MyDashboardComponent
  extends AbstractBaseComponent
  implements OnInit, OnDestroy {
  isLoggedIn: boolean;

  // Saved Hypotheticals Card
  hypotheticalTableProperties: HypotheticalTablePropertiesInfo;
  savedHypotheticalsCard: Card;
  savedHypotheticalsData: Array<HypotheticalInfo>;
  visibleHypotheticalsData: Array<HypotheticalInfo>;
  showSavedHypotheticals: boolean;

  // Sales Team Card
  salesTeamCard: Card;
  salesTeamObj: SalesTeamRootObject;
  isAdvisorConsultantAssigned: boolean;
  showSalesTeamCard: boolean;
  repDetails: RepDetails[];

  // Favorite Funds Team Card
  favFundsTableProperties: FavoriteFundTablePropertiesInfo;
  favFunds: FavouriteFund[] = [];
  favFundsCard: Card;
  pageLoad = true;
  showFavFundCard: boolean;

  // Accounts Team Card
  accLinksInfo: CardDashboardAccountsLinksInfo[] = [];
  dashAcc: AccountsOptions[];
  dashboardAccCard: Card;
  showAccountCard: boolean;
  accData: CardDashboardAccountsData;
  imageSrc: string;
  dashTitle: string;
  accountAccess: boolean;

  // My Literature Card
  literatureTableProperties: LiteratureTablePropertiesInfo;
  toggleButtons: ToggleButton[];
  literatureDocumentData: LiteratureDocumentInfo[];
  literatureCard: Card;
  literatureHistoryData: LiteratureHistoryData;
  recentOrderHistoryListData: OrderHistoryDetail;
  dialogData = [];
  public modalId = 'searchCartModal';
  showLiteratureCard: boolean;
  viewedLit$: Observable<LiteratureHistoryData>;
  orderedLit$: Observable<OrderHistoryDetail>;

  ModalId = ModalId;
  parameters: DashboardParameters;
  labels: DashboardLabels;
  profile: IUserProfile;
  productTypeOptions: FlyoutMenu[] = [];
  preSelectedValue: number;
  hasFavourite = false;
  productType = ConfigurationId.MF;
  favourites: PersonalisationFavoriteData[] = [];

  cards: DashboardContentCard[];

  /**
   * used to remove subscriptions when component is destroyed
   */
  private unsubscribe$: Subject<void> = new Subject<void>();

  private static getSavedHypotheticals(
    input: Output[],
    profileInfo: IUserProfileInfo
  ): HypotheticalInfo[] {
    return input.map((item) => ({
      name: item.Name,
      // Add profile hypotool token in query string
      url: item.Link ? item.Link + '&YYY2331_' + profileInfo?.hypotool : '',
      details: item.Description,
      items: [
        {
          label: item.Type,
          val: item.Type,
        },
        {
          label: dayjs(item.LastEditedDate).utc().format('MM/DD/YYYY'),
          val: dayjs(item.LastEditedDate).utc().format('MM/DD/YYYY'),
        },
      ],
    }));
  }

  constructor(
    private myDashboardService: MyDashboardService,
    private envConfigService: EnvConfigService,
    private profileService: ProfileService,
    @Inject(WINDOW) readonly windowRef: Window,
    @Inject(DOCUMENT) readonly documentObj: Document,
    private renderer2: Renderer2,
    private translateService: TranslateService,
    private myliteratureService: MyliteratureService,
    private cartHandlerService: CartHandlerService,
    private changeDetectorRef: ChangeDetectorRef,
    private favouriteService: FavoritesService,
    private navigationService: NavigationService,
    private viewModeService: ViewModeService,
    private modalService?: ModalService,
    private appStateService?: AppStateService,
    private linkService?: LinkService,
    private widenService?: WidenService
  ) {
    super();
    this.hasFavourite = this.favouriteService.isFavouriteEnabled();
    this.isEditMode = this.viewModeService.isEditMode();
  }

  ngOnInit(): void {
    this.parameters = this.component?.getParameters();
    this.labels = this.component?.getModels()['ft.core.dashboard'];
    this.cards = this.getCards() || [];
    this.initStaticData();
    this.favouriteService
      .getFavorites$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((favourites: PersonalisationFavoriteData[]) => {
        if (favourites?.length > 0) {
          this.favourites = favourites;
          this.setFavFundData();
        } else {
          this.setToEmptyStateFav();
        }
      });

    this.visibleHypotheticalsData = [];
    this.savedHypotheticalsData = [];

    this.profileService
      .getUserProfile$()
      ?.pipe(
        takeUntil(this.unsubscribe$),
        filter((profile: IUserProfile): boolean => {
          if (profile.isLoggedIn) {
            return !!profile?.profileInfo?.userId;
          } else {
            return true;
          }
        })
      )
      .subscribe(
        (profileData: IUserProfile): void => {
          this.profile = profileData;
          this.isLoggedIn = profileData.isLoggedIn;
          this.accLinksInfo = this.getAccDashData();
          // My favorites and My literature to get displayed for logged in FP , SH and MOS
          this.showLiteratureCard =
            this.parameters.showLiterature &&
            this.isLoggedIn &&
            (this.profile?.profileInfo?.role === FP ||
              this.profile?.profileInfo?.role === SH);

          this.showFavFundCard =
            this.parameters.showFavoriteFunds &&
            this.isLoggedIn &&
            (this.profile?.profileInfo?.role === FP ||
              this.profile?.profileInfo?.role === SH);
          this.savedHypotheticalsCard = {
            title: this.labels?.hypoCardTitle,
            ctaLink: replaceTokens(
              // Replace tokens on homepage after profile info has been set
              this.translateService.instant('common.launchHypotheticalsUrl'),
              {},
              this.profile
            ),
            state: CardState.Loading,
            ctaText: this.labels?.hypoSeeAllButton,
            warningImgSrc:
              'https://franklintempletonprod.widen.net/content/waunmvetiu/original/ft-global-icon-literature-type-fact-sheet-blue.svg',
            warningBtnLabel: this.labels?.hypoWarningBtnLabel,
            warningCardBody: this.labels?.hypoWarningCardBody,
            href: replaceTokens(
              // Replace tokens on homepage after profile info has been set
              this.translateService.instant('common.launchHypotheticalsUrl'),
              {},
              this.profile
            ),
            target: '_blank',
          };

          /*
           ** My Saved Hypotheticals
           */
          if (this.parameters.showSavedHypotheticals) {
            // Show Saved Hypos to logged in Financial Professional
            if (this.profile?.profileInfo?.role === FP) {
              this.showSavedHypotheticals = true;
              this.myDashboardService
                .getSavedHypos$(
                  this.profile.profileInfo?.hypotool,
                  this.envConfigService.getEnvConfig().hypoBaseUrl +
                    this.envConfigService.getEnvConfig()
                      .hypoSavedPresentationsUrl
                )
                .pipe(pluck('Output'), takeUntil(this.unsubscribe$))
                .subscribe(
                  (result: Output[]) => {
                    this.savedHypotheticalsData = MyDashboardComponent.getSavedHypotheticals(
                      result,
                      this.profile.profileInfo
                    );
                    // Show all the hypos by default
                    this.visibleHypotheticalsData = [
                      ...this.savedHypotheticalsData,
                    ];

                    if (this.visibleHypotheticalsData.length === 0) {
                      this.savedHypotheticalsCard.state = CardState.Empty;
                      // Hide the See all button if the list is empty
                      delete this.savedHypotheticalsCard.ctaText;
                    } else {
                      this.savedHypotheticalsCard.state = CardState.Basic;
                    }
                  },
                  () => {
                    this.savedHypotheticalsCard.state = CardState.Empty;
                  }
                );
            }
          }

          /*
           ** My Sales Team Contacts
           */
          if (this.parameters.salesTeams) {
            // Show only for FP role
            if (this.profile?.profileInfo?.role === FP) {
              this.showSalesTeamCard = true;
            }
          }

          /*
           ** My Literature
           */
          if (this.showLiteratureCard) {
            this.cartHandlerService
              .getCartItems()
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(
                (item: LiteratureDocumentListingState) => {
                  if (
                    CartActionPerformed.ADDED_FROM_LITERATURE_DASHBOARD ===
                    item.cartAction
                  ) {
                    this.dialogData = [];
                    this.dialogData = item.cartItems;
                    this.openModal(this.modalId);
                    this.changeDetectorRef.detectChanges();
                  }
                },
                () => {
                  this.literatureCard.state = CardState.Error;
                }
              );

            // Observable for viewed Literature
            this.viewedLit$ = this.myliteratureService
              .populateLiteratureHistory(profileData.profileInfo)
              .pipe(takeUntil(this.unsubscribe$));

            // Observable for ordered Literature
            this.orderedLit$ = this.myliteratureService
              .getRecentOrderHistoryList(profileData.profileInfo)
              .pipe(takeUntil(this.unsubscribe$));
          }

          const requestArray = [this.viewedLit$, this.orderedLit$];
          forkJoin(requestArray)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (result) => {
                // viewed Literature
                const litData: LiteratureHistoryData = result[0] as LiteratureHistoryData;
                this.literatureHistoryData = litData;
                this.literatureDocumentData = this.myliteratureService.mapLiteratureHistory(
                  litData,
                  null,
                  5
                );

                // ordered Literature
                this.recentOrderHistoryListData = result[1] as OrderHistoryDetail;
                this.literatureDocumentData = this.myliteratureService.mapLiteratureHistory(
                  this.literatureHistoryData,
                  this.recentOrderHistoryListData,
                  5
                );

                const isViewedEmpty =
                  this.literatureDocumentData[0]?.records?.length === 0;
                const isOrderedEmpty =
                  this.literatureDocumentData[1]?.records?.length === 0;
                if (isViewedEmpty && isOrderedEmpty) {
                  this.literatureCard.state = CardState.Empty;
                  // Hide the See all button and subtitle if the list is empty
                  delete this.literatureCard.ctaText;
                  delete this.literatureCard.subTitle;
                } else {
                  this.literatureCard.state = CardState.Basic;
                }
                this.changeDetectorRef.detectChanges();
              },
              (err) => {
                if (err?.status === 404) {
                  logger.warn(
                    '[getRecentOrderHistoryList] or [populateLiteratureHistory]' +
                      err?.message +
                      ' code: ' +
                      err?.error?.error?.errorList[0]?.code
                  );
                }
                this.literatureCard.state = CardState.Empty;
              }
            );

          // to remove the tick icon when document is deleted from cart
          this.cartHandlerService
            .getDeletedDocId()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((data) => {
              this.literatureDocumentData.forEach((category) => {
                category.records.forEach((recordData) => {
                  const index = data.findIndex(
                    (item) => item === recordData.docId
                  );
                  if (index > -1) {
                    recordData.added = false;
                  }
                });
              });
            });
        },
        () => {
          // Show error state on all cards when profile svc fails
          this.salesTeamCard.state = CardState.Error;
          this.savedHypotheticalsCard.state = CardState.Error;
          this.literatureCard.state = CardState.Error;
          // this.favFundsCard.state = CardState.Error;
        }
      );
  }

  /**
   * Get document from page model
   */
  private getCards(): DashboardContentCard[] {
    const cardsModel = this.component.getModels().Cards;
    const cards = this.page.getContent(cardsModel)?.getData()?.cards;
    cards?.forEach((card: DashboardContentCard) => {
      card.imageSrc = this.widenService.getWidenAssetUrl(
        card.image?.widenAsset
      );
    });
    return cards;
  }

  /*
   ** Get Accounts Details
   */
  private getAccDashData(): AccountsOptions[] {
    const dashboardLinkDetails = this.getDashData('linkCompound');
    const imageDetails = this.getDashData('linkWidenAsset');
    this.accData = {
      imgSrc: this.widenService.getWidenAssetUrl(
        imageDetails?.widenAssetCompound?.widenDocument
      ),
      defaultMessage: ' ',
    };
    this.dashAcc = [];
    dashboardLinkDetails?.forEach((card: CardData) => {
      this.dashAcc.push({
        name: card?.linkCollection[0]?.displayText,
        url: this.linkService.getCTALink(
          this.page,
          card?.linkCollection[0],
          this.appStateService?.getHomePageUrl()
        ),
        type: card?.linkCollection[0]?.displayText,
      });
    });
    this.dashTitle = this.getDashData('dashTitle');
    this.dashboardAccCard = {
      title: this.dashTitle,
      state: CardState.Empty,
      warningImgSrc:
        'https://franklintempletonprod.widen.net/content/2ahxrh4hvs/original/ft-global-icon-portfolio.svg',
    };
    this.dashboardAccCard.state =
      this.dashAcc.length > 0 ? CardState.Basic : CardState.Empty;
    return this.dashAcc;
  }

  private getDashData(type: string) {
    const mydashboardDocumentDetails = this.document?.getData();
    const profileVal = this.profile?.profileInfo?.role;
    this.accountAccess =
      this.profile?.profileInfo?.accountsAccess === AccountsAccess.ACCESS;
    switch (type) {
      case 'linkCompound':
        return profileVal !== FP
          ? mydashboardDocumentDetails?.linkCompound1
          : mydashboardDocumentDetails?.linkCompound;
        break;
      case 'linkWidenAsset':
        return profileVal !== FP
          ? mydashboardDocumentDetails?.linkWidenAsset1
          : mydashboardDocumentDetails?.linkWidenAsset;
        break;
      case 'dashTitle':
        return profileVal !== FP
          ? this.labels?.accDashTitle1
          : this.labels?.accDashTitle;
        break;
      default:
        return profileVal !== FP
          ? this.labels?.accDashTitle1
          : this.labels?.accDashTitle;
        break;
    }
  }
  /*
   ** Populate Representative Details
   *  for Sales Team Card
   */
  private populateRepDetails() {
    if (this.salesTeamObj?.repSvcTmsVO?.intWhId) {
      this.isAdvisorConsultantAssigned = true;
    }

    const salesTmPhNbr: string = maskUsPhoneNo(
      get(this.salesTeamObj, 'repSvcTmsVO.salesTmPhNbr', '')
    );
    const advisorRep: RepDetails = {
      // name
      title: `${get(this.salesTeamObj, 'repSvcTmsVO.intWhFrstNm', '')} ${get(
        this.salesTeamObj,
        'repSvcTmsVO.intWhLstNm',
        ''
      )}`,
      // title
      text1: this.labels.adConsultant,
      // phone number
      text2: `${salesTmPhNbr} x${get(
        this.salesTeamObj,
        'repSvcTmsVO.intWhExt',
        ''
      )}`,
      // Photo
      imgSrc: get(
        this.salesTeamObj,
        'repSvcTmsVO.intWhContact[0].imagePath',
        ''
      ),
      fallbackImgSrc: DEFAULT_IMAGE_SRC,
    };
    advisorRep.title = titleCase(advisorRep.title);
    // NGC-11054: hide rep details if its empty
    if (advisorRep.title.trim() !== '') {
      this.repDetails.push(advisorRep);
    }

    const srAdvisorRep: RepDetails = {
      // name
      title: `${get(this.salesTeamObj, 'repSvcTmsVO.extWhFrstNm', '')} ${get(
        this.salesTeamObj,
        'repSvcTmsVO.extWhLstNm',
        ''
      )}`,
      // title
      text1: this.labels.srAdConsultant,
      // Photo
      imgSrc: get(
        this.salesTeamObj,
        'repSvcTmsVO.extWhContact[0].imagePath',
        ''
      ),
      fallbackImgSrc: DEFAULT_IMAGE_SRC,
    };
    srAdvisorRep.title = titleCase(srAdvisorRep.title);
    // NGC-11054: hide rep details if its empty
    if (srAdvisorRep.title.trim() !== '') {
      this.repDetails.push(srAdvisorRep);
    }
  }

  private currentDate(): string {
    return dayjs(new Date()).format('MM/DD/YYYY');
  }

  private initStaticData() {
    this.menuItem = getMenus(this.page, this.component);

    this.hypotheticalTableProperties = {
      name: this.labels?.hypoColHeadingName,
      type: this.labels?.hypoColHeadingType,
      date: this.labels?.hypoColHeadingDate,
      buttonOpenInput: this.labels?.hypoOpenButton,
    };

    this.salesTeamCard = {
      title: this.labels?.salesCardTitle,
      ctaLink: '',
      ctaText: this.labels?.salesBookButton,
      body: this.labels?.salesCardDescription,
      footer: this.labels?.salesCardFooter,
      state: CardState.Loading,
      warningImgSrc:
        'https://franklintempletonprod.widen.net/content/alpwkbmxpi/original/ft-global-icon-communication-org.svg',
      warningCardBody: this.labels?.salesWarningCardBody,
      warningBtnLabel: this.labels?.salesWarningBtnLabel,
    };

    /**
     * To Do: Replace the hard coded static data
     * with API data when working My Fav funds Card
     */
    this.favFundsCard = {
      title: this.labels?.favFundsCardTitle,
      state: CardState.Empty,
      warningImgSrc:
        'https://franklintempletonprod.widen.net/content/2ahxrh4hvs/original/ft-global-icon-portfolio.svg',
      warningBtnLabel:
        this.labels?.favFundsEmptyButton || this.labels?.favFundsSeeAllButton,
      warningCardBody: this.labels?.favFundsTemporaryMsg,
    };
    this.favFundsCard.dropDownItem = [];
    if (this.hasFavourite) {
      this.favFundsCard.ctaText = this.labels?.favFundsSeeAllButton;
      this.favFundsCard.ctaLink =
        '/investments/options/mutual-funds?showFavOnly=true&ref=home'; // WDE-1260: Add query param for fav toggle 'ON' state.
      this.favFundsCard.subTitle = '';
      this.favFundsCard.asOfDate = this.currentDate();
      this.favFundsCard.asOfLabel = this.labels?.favFundsAsOf;
      this.favFundsCard.state = CardState.Basic;
      this.favFundsCard.dropDownTitle = this.translateService.instant(
        'products.product-type-select'
      );
      this.favFundsCard.dropDownItem = [];
      this.favFundsCard.dropDownWidth = 'md';
      this.favFundsCard.warningCardBody = this.labels?.favFundsNoData;
      this.favFundsCard.titleCaveatPlacement = 'FavDashboardTitlePlacement';
    }

    this.favFundsTableProperties = {
      name: this.labels?.favFundsColHeadingFundName,
      value: this.labels?.favFundsColHeadingYTD,
      action: '',
      defaultMessage: this.labels?.favFundsNoData,
      deleteLabel: this.labels?.favFundsDelete,
      valueCaveatPlacement: 'FavTableYtdReturnColumnPlacement',
      nameCaveatPlacement: 'FavTableNameColumnPlacement',
    };
    if (this.menuItem) {
      this.mapProductTypeOptions();
    }
    /**
     * My Literature Card Table Data
     */
    this.literatureTableProperties = {
      docType: this.labels?.myLitColHeadingType,
      docName: this.translateService.instant(
        'literature.lit-listing-column-name'
      ),
      downloadTitle: this.translateService.instant('literature.download'),
      addToCartTitle: this.translateService.instant('literature.ADD_TO_CART'),
      defaultMessage: this.labels?.myLitNoData,
      addedToCartTitle: this.translateService.instant(
        'literature.added-to-cart'
      ),
    };

    /**
     * My Literature Card Tab Buttons
     */
    this.toggleButtons = [
      {
        active: true,
        text: this.translateService.instant('literature.viewed'),
      },
      {
        active: false,
        text: this.translateService.instant('literature.recently-ordered'),
      },
    ];

    /**
     * My Literature Card
     */
    this.literatureCard = {
      title: this.translateService.instant(
        'literature.lit-my-literature-title'
      ),
      subTitle: this.translateService.instant(
        'literature.lit-see-all-literature'
      ),
      ctaLink: 'tools-and-resources/literature/index',
      href: 'tools-and-resources/literature/index', // Search Literature button
      ctaText: this.translateService.instant('literature.see-all'),
      state: CardState.Loading,
      warningImgSrc:
        'https://franklintempletonprod.widen.net/content/wtb0qhi1jn/original/ft-global-icon-book-org.svg',
      warningBtnLabel: this.labels?.myLitWarningBtnLabel,
      warningCardBody: this.labels?.myLitWarningCardBody,
    };
  }

  public openHistoryPage(event: Card) {
    this.myliteratureService.navigateToUrl(event.ctaLink);
  }

  public addToCart(event: RecordInfo) {
    // to change the add to cart icon for same document in multiple tab
    this.literatureDocumentData.forEach((category) => {
      category.records.forEach((recordData) => {
        if (recordData.docId === event.docId) {
          recordData.added = true;
        }
      });
    });
    event.added = true;
    let literatureData;
    literatureData = this.myliteratureService.getLiteratureDocument(
      this.literatureHistoryData,
      this.recentOrderHistoryListData?.document,
      event
    );
    const cartData: LiteratureDocumentListingData = {
      literatureData,
    };
    this.cartHandlerService.addToCart(
      [cartData],
      CartActionPerformed.ADDED_FROM_LITERATURE_DASHBOARD
    );
  }

  public openModal(modelId: string) {
    const config: SimpleModalConfig = {
      modalId: modelId,
      closeBtnLabel: this.translateService.instant(
        'literature.lit-sticky-close-button'
      ),
      title: '',
      type: 'simple',
      body: '',
      themeTitle: 'dark',
      preTitle: '',
      eventName: 'lightbox_display',
      eventAction: 'Add to Cart', // EDS Analytics Event tracking name
    };
    this.modalService.open(modelId, config);
  }

  public downloadDocument(event: RecordInfo) {
    this.myliteratureService.downloadDocument(event.downloadUrl);
    this.cartHandlerService.checkoutOrder([
      { literatureCode: event.docId, qty: 1 },
    ]);
  }

  public closeDialog(dialogId: string): void {
    this.modalService?.close(dialogId);
  }

  public deleteFromCart(docId: string) {
    this.cartHandlerService.removeFromCart([docId]);
    if (this.dialogData.length === 0) {
      this.closeDialog(this.modalId);
    }
  }

  /**
   * Map product type options only.
   */
  private mapProductTypeOptions() {
    this.menuItem.siteMenuItems?.forEach((item, i) => {
      this.favFundsCard.dropDownItem.push({
        link: item?.name,
        href: '#',
        id: i,
        name: item?.name,
        active: false,
        child: [],
      });
    });
    this.favFundsCard.dropDownTitle = Array.isArray(this.menuItem.siteMenuItems)
      ? this.menuItem.siteMenuItems[0]?.name
      : '';
  }

  public selectedIndex(event) {
    this.favFundsCard.dropdownSelectedId = event;
    if (this.menuItem?.siteMenuItems) {
      this.productType = this.menuItem.siteMenuItems[event]?.parameters
        ?.productType as ConfigurationId;
      this.pageLoad = false;
      this.setFavFundData();
      this.favFundsCard.ctaLink =
        (this.menuItem.siteMenuItems[event]?.links?.site?.href ||
          '/investments/options/mutual-funds') + '?showFavOnly=true&ref=home'; // WDE-1260: Add query param for fav toggle 'ON' state.
      this.favFundsCard.href = this.favFundsCard.ctaLink;
      this.favFundsCard.dropDownTitle = this.menuItem.siteMenuItems[
        event
      ]?.name;
    }
  }

  private setFavFundData() {
    if (this.favourites?.length > 0) {
      this.favFundsCard.state = CardState.Loading;
      this.myDashboardService.populateFavourite(this.favourites).subscribe(
        (favouriteData: FavouriteFund[]) => {
          if (this.hasFavourite) {
            this.favFunds = favouriteData.filter((favFund) => {
              return favFund?.productType === this.productType;
            });

            if (this.favFunds.length === 0 && this.pageLoad) {
              this.pageLoad = false;
              // Finding Largest data set By product Type
              let LargestDataSetCount = 0;
              let dropDownIndex = 0;
              this.menuItem?.siteMenuItems.forEach((menuItem: any, i) => {
                const LargestDataSet = favouriteData.filter((favFund) => {
                  return (
                    favFund?.productType === menuItem?.parameters?.productType
                  );
                });
                const count = LargestDataSet?.length;
                if (count > LargestDataSetCount) {
                  this.productType = menuItem?.parameters?.productType;
                  LargestDataSetCount = count;
                  this.favFunds = LargestDataSet;
                  dropDownIndex = i;
                }
              });

              this.favFundsCard.dropdownSelectedId = dropDownIndex;
              this.favFundsCard.dropDownTitle = this.menuItem?.siteMenuItems[
                dropDownIndex
              ]?.name;
              this.favFundsCard.ctaLink =
                (this.menuItem?.siteMenuItems[dropDownIndex]?.links?.site
                  ?.href || '/investments/options/mutual-funds') +
                '?showFavOnly=true&ref=home'; // WDE-1260: Add query param for fav toggle 'ON' state.
              this.favFundsCard.href = this.favFundsCard.ctaLink;
            }

            // Set nav as of date
            if (this.favFunds.length > 0) {
              this.favFundsCard.state = CardState.Basic;
              this.setTableYtdHeader(this.productType, this.favFunds);

              // Show fav funds shorted by name which includes fundname,shareclassname & identifer
              this.favFunds = this.favFunds.sort((a, b) => {
                // to apply custom sort when string contains 'number-number' pattern
                if (a.name.match(/\d+-\d+/g) || b.name.match(/\d+-\d+/g)) {
                  return this.sortAlphaNum(a.name, b.name);
                } else {
                  return a.name.localeCompare(b.name);
                }
              });
              this.favFundsCard.asOfDate = this.favFunds[0]?.asOfDate
                ? this.favFunds[0]?.asOfDate
                : this.currentDate();
              this.changeDetectorRef.detectChanges();
            } else {
              this.setToEmptyStateFav();
            }
          }
        },
        (error) => {
          this.favFundsCard.state = CardState.Error;
          this.changeDetectorRef.detectChanges();
        }
      );
    } else {
      this.setToEmptyStateFav();
    }
  }

  private setToEmptyStateFav() {
    this.favFundsCard.asOfDate = this.currentDate();
    this.favFundsCard.state = CardState.Empty;
    this.favFundsCard.warningBtnLabel =
      this.labels?.favFundsEmptyButton || this.labels?.favFundsSeeAllButton;
    this.favFundsCard.warningCardBody = this.labels?.favFundsNoData;
    this.favFundsCard.warningImgSrc =
      'https://franklintempletonprod.widen.net/content/2ahxrh4hvs/original/ft-global-icon-portfolio.svg';
    this.favFundsCard.href = this.favFundsCard.ctaLink;
    this.changeDetectorRef.detectChanges();
  }

  private sortAlphaNum(a: string, b: string): number {
    const reA = /[^a-zA-Z]/g;

    const reN = /[^0-9]/g;

    const aA = a.replace(reA, '');

    const bA = b.replace(reA, '');

    if (aA === bA) {
      const aN = parseInt(a.replace(reN, ''), 10);

      const bN = parseInt(b.replace(reN, ''), 10);

      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  }

  private setTableYtdHeader(
    productType: ConfigurationId,
    favFunds: FavouriteFund[]
  ) {
    this.favFundsTableProperties.value = this.labels.favFundsColHeadingYTD;
    if (
      productType === ConfigurationId.CEF ||
      productType === ConfigurationId.ETF
    ) {
      this.favFundsTableProperties.value = this.labels.favFundsColHeadingYTDMarketPrice;
    }

    // Set the mobile view label
    this.favFunds?.forEach((favFund: FavouriteFund) => {
      if (favFund.items) {
        favFund.items[0].label = this.favFundsTableProperties.value;
      }
    });
  }

  public removeFavFunds(fund: FavoriteFundInfo) {
    const fundToRemove = this.favFunds.find((favFund: FavouriteFund) => {
      return favFund?.url === fund?.url && favFund?.name === fund?.name;
    });
    this.favouriteService.toggleFavorite(this.getFavoriteDetail(fundToRemove));
  }

  // get required details to remove the favorite
  private getFavoriteDetail = (
    fund: FavouriteFund
  ): FavoritesRequestDetail => ({
    fundName: fund.fundName,
    productType: fund.productType as ProductType,
    fundUrl: fund.url,
    fundShareClassId: (fund?.fundId +
      '-' +
      fund?.shareClassCode) as FundShareClassId,
  });

  public openPPSSPage(event: Card) {
    this.navigationService.navigateByUrl(event.ctaLink);
  }

  ngOnDestroy() {
    this.renderer2.destroy();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
