import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { InfoItem } from '@frk/eds-components';
import { LiteratureDataService } from '@literature/services/literature-data.service';
import { LiteratureGlobalConfigService } from '@literature/services/literature-global-config.service';
import { NAVChangeDirection } from '@models';
import { EMDASH } from '@products/utils/constants/product.constants';
import {
  MUTUAL_FUNDS,
  SMA,
  VIP,
  VIP_LOGGED,
  PORTFOLIOS_529,
  CLOSED_END,
  ETF,
  MONEY_MARKET,
  INTERVAL_FUNDS,
  DEFAULT,
} from '@search/interfaces/products.constants';
// TODO cleanup types Avatar, DocThumbnail
import {
  ExactMatch,
  ExactMatchFundData,
  NavData,
  DocThumbnail,
  ExactMatchDataNode,
  ExactMatchLitDataNode,
  FtBrResponse,
  FtBrContentBlocks,
  FundAlert,
} from '@search/interfaces/search.interface';
import { ConfigService } from '@search/services/config.service';
import { SiteConfigService } from '@services/site-config.service';
import { TranslateService } from '@shared/translate/translate.service';
import { FundId, FundIdentifier, SegmentId, ShareClassCode } from '@types';
import { fromLocaleNumber } from '@utils/il8n/number-il8n';
import { Logger } from '@utils/logger';
import { Observable, Subject, of, Subscription, throwError } from 'rxjs';
import {
  tap,
  switchMap,
  share,
  catchError,
  pluck,
  retry,
  map,
} from 'rxjs/operators';
import { createNavigationString } from '@utils/text/string-utils';

const logger = Logger.getLogger('FundDetailsService');

export interface FundInfoElem {
  node: string;
  label: string;
}
interface FundInfoConfig {
  name: string;
  priceElements: string[];
  fundInfoElements: FundInfoElem[];
}

// we don't need this config in Bloomreach.
// it can be hardocded in a component
const fundDetailsConfig: FundInfoConfig[] = [
  {
    name: MUTUAL_FUNDS,
    priceElements: ['NAV_VALUE', 'NAV_CHG', 'YTD_TOT_RET'],
    fundInfoElements: [
      { node: 'netAssets', label: 'total-net-assets' },
      { node: 'expratgross', label: 'gross-expense' },
      { node: 'expratnet', label: 'net-expense' },
      { node: 'maxinitchrg', label: 'initial-sales' },
    ],
  },
  {
    name: SMA,
    priceElements: ['YTD_CUM_NET', 'YTD_CUM_GROSS', 'YTD_CUM_PUREGROSS'],
    fundInfoElements: [{ node: 'assetclass', label: 'asset-class' }],
  },
  {
    name: VIP,
    priceElements: ['NAV_VALUE'],
    fundInfoElements: [
      { node: 'netAssets', label: 'total-net-assets' },
      { node: 'expratgross', label: 'gross-expense' },
      { node: 'expratnet', label: 'net-expense' },
      { node: 'maxinitchrg', label: 'initial-sales' },
    ],
  },
  {
    name: VIP_LOGGED,
    priceElements: ['NAV_VALUE', 'NAV_CHG', 'YTD_TOT_RET'],
    fundInfoElements: [
      { node: 'netAssets', label: 'total-net-assets' },
      { node: 'expratgross', label: 'gross-expense' },
      { node: 'expratnet', label: 'net-expense' },
      { node: 'maxinitchrg', label: 'initial-sales' },
    ],
  },
  {
    name: PORTFOLIOS_529,
    priceElements: ['NAV_VALUE', 'NAV_CHG', 'YTD_TOT_RET'],
    fundInfoElements: [
      { node: 'netAssets', label: 'total-net-assets' },
      { node: 'expratgross', label: 'gross-expense' },
      { node: 'expratnet', label: 'net-expense' },
      { node: 'maxinitchrg', label: 'initial-sales' },
    ],
  },
  {
    name: CLOSED_END,
    priceElements: ['NAV_VALUE', 'NAV_CHG', 'YTD_TOT_RET'],
    fundInfoElements: [
      { node: 'netAssets', label: 'total-net-assets' },
      { node: 'expratgross', label: 'gross-expense' },
      { node: 'expratnet', label: 'net-expense' },
      { node: 'maxinitchrg', label: 'initial-sales' },
    ],
  },
  {
    name: MONEY_MARKET,
    priceElements: ['NAV_VALUE', 'NAV_CHG', 'YTD_TOT_RET'],
    fundInfoElements: [
      { node: 'netAssets', label: 'total-net-assets' },
      { node: 'expratgross', label: 'gross-expense' },
      { node: 'expratnet', label: 'net-expense' },
      { node: 'maxinitchrg', label: 'initial-sales' },
    ],
  },
  {
    name: ETF,
    priceElements: ['NAV_VALUE', 'NAV_CHG', 'YTD_TOT_RET'],
    fundInfoElements: [
      { node: 'netAssets', label: 'total-net-assets' },
      { node: 'expratgross', label: 'gross-expense' },
      { node: 'expratnet', label: 'net-expense' },
      { node: 'fundincdt', label: 'fund-inception-date' },
      { node: 'assetclass', label: 'asset-class' },
      { node: 'invvehcile', label: 'etf-type' },
    ],
  },
  {
    name: INTERVAL_FUNDS,
    priceElements: ['NAV_VALUE', 'NAV_CHG', 'YTD_TOT_RET'],
    fundInfoElements: [
      { node: 'netAssets', label: 'total-net-assets' },
      { node: 'expratgross', label: 'gross-expense' },
      { node: 'expratnet', label: 'net-expense' },
      { node: 'maxinitchrg', label: 'initial-sales' },
    ],
  },
  {
    name: DEFAULT,
    priceElements: ['NAV_VALUE', 'NAV_CHG', 'YTD_TOT_RET'],
    fundInfoElements: [
      { node: 'netAssets', label: 'total-net-assets' },
      { node: 'expratgross', label: 'gross-expense' },
      { node: 'expratnet', label: 'net-expense' },
      { node: 'maxinitchrg', label: 'initial-sales' },
    ],
  },
];

const SERVICE_TYPE = 'literatureUS';

@Injectable({
  providedIn: 'root',
})
export class FundDetailsService implements OnDestroy {
  exactMatch: ExactMatch;
  fundsData: ExactMatchDataNode;
  literatureData: ExactMatchDataNode;
  fundDataNode: ExactMatchFundData;
  literatureDataNode: ExactMatchLitDataNode[];
  fundId: FundId;
  ticker: FundIdentifier;
  shareClassCode: ShareClassCode;
  fundName: string;
  priceElements: string[];
  fundInfoElements: FundInfoElem[];

  dataSubscription: Subscription;
  suppressedShareclasses: ShareClassCode[];

  private fundDescriptionInput$: Subject<string>;
  fundDescriptionOutput$: Observable<FtBrContentBlocks[]>;
  litDownloadUrl: string;

  constructor(
    protected literatureDataService: LiteratureDataService,
    protected literatureConfigService: LiteratureGlobalConfigService,
    protected translateService: TranslateService,
    protected siteConfigService: SiteConfigService,
    private configService: ConfigService,
    private http: HttpClient
  ) {
    this.suppressedShareclasses = this.configService.getSuppressedShareclasses();
    this.litDownloadUrl = this.literatureDataService.getDownloadUrl(
      SERVICE_TYPE
    );

    this.fundDescriptionInput$ = new Subject();
    this.fundDescriptionOutput$ = this.fundDescriptionInput$.pipe(
      tap(() => {
        logger.debug('reading fund description');
      }),
      switchMap((url) => {
        logger.debug('trying to get data from backend' + url);
        return this.http.get<FtBrResponse>(url).pipe(
          tap(
            (response) => {
              logger.debug('trying to process response' + response);
            },
            () => {
              logger.error(
                '[search] [fundDescription] Received error from backend request while requesting: ' +
                  url
              );
            }
          ),
          retry(1),
          pluck('content'),
          map((content) =>
            Object.keys(content)
              .map((k) => content[k])
              .filter((block) => block.category === 'Fund Objective')
          ),
          catchError((err) => {
            logger.error(
              '[search] [fundObjective] Error while receiving overview data from backend service: ' +
                JSON.stringify(err.message)
            );
            return throwError(err);
          })
        );
      }),
      share()
    );
  }

  /**
   *
   */
  setExactResults(exactMatch: ExactMatch): void {
    this.fundsData = exactMatch.data.find(
      (element) => element.name === 'funds'
    );
    this.literatureData = exactMatch.data.find(
      (element) => element.name === 'literature'
    );
    // TODO - add error handling in case API response is not correct.

    this.fundDataNode = this.fundsData.response.hits.hits[0]._source;
    this.literatureDataNode = this.literatureData.response?.hits
      ?.hits as ExactMatchLitDataNode[];

    this.fundId = this.fundDataNode.fundid;
    this.shareClassCode = this.fundDataNode.shclcode;
    this.fundName = this.fundDataNode.title;
    this.ticker = this.fundDataNode.ticker;

    // we modify type for loggedIn VIP
    let productType = this.fundDataNode.producttype;
    if (this.configService.getIsLoggedIn() && productType === VIP) {
      productType = VIP_LOGGED;
    }

    this.priceElements = fundDetailsConfig.filter(
      (element: FundInfoConfig) => element.name === productType
    )[0]?.priceElements;

    this.fundInfoElements = fundDetailsConfig.filter(
      (element: FundInfoConfig) => element.name === productType
    )[0]?.fundInfoElements;

    // Error handling in case of new product type
    if (!this.priceElements) {
      logger.debug(
        'product type: ' +
          productType +
          ' not found. Reading default settings for price elements'
      );
      this.priceElements = fundDetailsConfig.filter(
        (element: FundInfoConfig) => element.name === 'default'
      )[0]?.priceElements;

      this.fundInfoElements = fundDetailsConfig.filter(
        (element: FundInfoConfig) => element.name === 'default'
      )[0]?.fundInfoElements;
    }
  }

  /**
   * returns product type returned by
   */
  getProductType(): string {
    return this.fundDataNode.producttype;
  }

  /**
   *
   */
  getFundTitle(): string {
    const fundTitle = this.fundDataNode.title;

    const shareClassSuffix =
      this.fundDataNode.shclcode &&
      !this.suppressedShareclasses.includes(this.fundDataNode.shclcode)
        ? ' - ' + this.fundDataNode.shclname
        : '';

    const fundIdentifierSuffix =
      this.fundDataNode.ticker && this.fundDataNode.ticker !== '-'
        ? ' - ' + this.fundDataNode.ticker
        : '';

    return fundTitle + shareClassSuffix + fundIdentifierSuffix;
  }

  /**
   * DCE-2219 - get fund alert data from elastic search exact match data
   * @returns FundAlert
   */
  public getFundAlertData(): FundAlert {
    return this.fundDataNode?.alert || null;
  }

  /**
   * DCE-2219 - Fund Alert Display In Current Segment
   * @param fundAlert: FundAlert
   * @returns boolean
   */
  public isFundAlertDisplayInCurrentSegment(fundAlert: FundAlert): boolean {
    if (fundAlert?.ppssMessage) {
      fundAlert.ppssMessage = fundAlert?.ppssMessage.trim();
    }
    if (fundAlert?.ppssTooltip) {
      fundAlert.ppssTooltip = fundAlert?.ppssTooltip.trim();
    }
    const currentSegment = this.configService.currentSegment as SegmentId;
    let displayFundAlert = false;
    if (fundAlert?.segments.length === 0) {
      displayFundAlert = true;
    } else {
      fundAlert?.segments.forEach((item) => {
        if (item === currentSegment) {
          displayFundAlert = true;
        }
      });
    }
    return displayFundAlert;
  }

  /**
   *
   * @returns gets NAV data
   */
  getNavData$(): Observable<NavData> {
    const navData: NavData = {
      updatedDaily: true, // set to true when we read NAV data
      asOfDate: this.fundDataNode.asofdate,
      nav: this.fundDataNode.navvalue,
      navChg: this.fundDataNode.navchngvalue,
      ytdTotal: this.fundDataNode.ytdtotretatnav,
      navChgDirection: this.fundDataNode.navchngvalue
        ? this.getValueDirection(this.fundDataNode.navchngvalue)
        : this.fundDataNode.navchngvalue,
    };
    return of(navData);
  }

  /**
   *
   * @param changeDirectionValue string describing direction
   */
  getValueDirection(changeDirectionValue: string): NAVChangeDirection {
    const changeDirVal = fromLocaleNumber(changeDirectionValue);
    let changeDirection: NAVChangeDirection;
    if (changeDirVal > 0) {
      changeDirection = 'gain';
    } else if (changeDirVal < 0) {
      changeDirection = 'loss';
    } else if (changeDirVal === 0) {
      changeDirection = 'static';
    }
    return changeDirection;
  }

  /**
   *
   */
  getFundInformation$(): Observable<InfoItem[]> {
    const infoItems = [];

    // netAssets is read from another source
    this.fundInfoElements
      .filter((element) => element.node !== 'netAssets')
      .forEach((element) => {
        infoItems.push({
          label: this.translateService.instant('products.' + element.label),
          val:
            element.node in this.fundDataNode
              ? this.fundDataNode[element.node]
              : EMDASH,
        });
      });

    return of(infoItems);
  }

  /**
   *
   */
  getFundDocuments$(): Observable<Array<DocThumbnail>> {
    const fundDocuments = this.literatureDataNode.map((litDocument) => {
      return {
        title: litDocument._source.lit_content_type,
        titleLink: this.getFundDocumentLink(litDocument),
        docTypeSrc: this.configService.getIconPath('pdf'),
      };
    });

    return of(fundDocuments);
  }

  getFundDocumentLink(litDocument: ExactMatchLitDataNode): string {
    if (
      this.literatureConfigService?.literatureConfig?.documentsWithPreview?.includes(
        litDocument._source.lit_content_type
      )
    ) {
      return this.getProductPreviewLink(litDocument._source.lit_content_type);
    }

    return (
      this.litDownloadUrl +
      (litDocument._source.lit_code !== ''
        ? litDocument._source.lit_code
        : litDocument._source.customfilePath)
    );
  }
  /**
   *  reads link to a product page for a fund
   */
  getProductPageLink(): string {
    const fundLink = this.siteConfigService.getFundLink(
      this.fundId as FundId,
      this.shareClassCode as ShareClassCode,
      this.fundName,
      this.ticker as FundIdentifier
    );
    return fundLink;
  }

  /**
   * reads link to preview page for a fund
   */
  getProductPreviewLink(litDocType: string): string {
    const fundLink = this.siteConfigService.getFundLink(
      this.fundId as FundId,
      this.shareClassCode as ShareClassCode,
      this.fundName,
      this.ticker as FundIdentifier,
      true // isLinkPreview
    );
    return fundLink + '#' + createNavigationString(litDocType);
  }

  /**
   * TODO - we may consider simplifying API service to deliver only Fund Description
   */
  getProductResourceAPILink(): string {
    const resourceApiUrl = this.configService.getResourceApiUrl();
    const fundLink = this.getProductPageLink();
    const parameters = '?segment=' + this.configService.currentSegment;

    return resourceApiUrl + fundLink + parameters;
  }

  /**
   *
   */
  getPriceAndYtdLabel(): string {
    const labelKey =
      this.getProductType() === SMA
        ? 'products.ytd-return-sma'
        : 'products.price-and-ytd-return';
    return this.translateService.instant(labelKey);
  }
  /**
   *
   */
  getFundDescription() {
    // get this from product page link
    logger.debug('triggering fund description call');
    const fundAPI = this.getProductResourceAPILink();
    this.fundDescriptionInput$.next(fundAPI);
  }

  ngOnDestroy() {
    this.dataSubscription?.unsubscribe();
  }
}
