import { Injectable, OnDestroy } from '@angular/core';
import { BMPerformanceDetail } from '@products/models/benchmark-performance';
import { PerformanceDetail } from '@products/models/performance';
import {
  BMCumulativePerformanceHistory,
  CumulativePerformanceHistory,
  CumulativePerformanceHistoryCategory,
  FundCumulativePerformanceHistory,
  PerformanceHistory,
} from '@products/models/performance-history';
import { Product } from '@products/models/product';
import { ShareClass } from '@products/models/share-class';
import { FundDetailService } from '@products/services/fund-detail.service';
import { FundPerformanceHistoryService } from '@products/services/fund-performance-history.service';
import {
  FundPerformanceCumulativeState,
  FundPerformanceCumulativeStateData,
  FundPerformanceCumulativePeriodEndData,
  FundPerformanceCumulativeTableData,
  FundPerformanceCumulativeGraphData,
  ChartCalculationType,
} from '@products/types/performance.type';
import { EMDASH } from '@products/utils/constants/product.constants';
import {
  BenchmarksToShow,
  SiteConfigService,
} from '@services/site-config.service';
import {
  CalcTypeStd,
  FundId,
  PerformanceSuppressionSet,
  PerformanceType,
  PerformanceView,
  ProductDetailConfiguration,
  ShareClassCode,
} from '@types';
import { symbols } from '@utils/il8n/currency';
import { Logger } from '@utils/logger';
import { stringCompare } from '@utils/sort-utils';
import {
  getStartOfQuarterInMilliseconds,
  getTimeStampInMilliseconds,
} from '@utils/text/date-utils';
import { parseNumber } from '@utils/text/number-utils';
import { forkJoin, Subject, Observable } from 'rxjs';
import { BaseFundPerformanceService } from './base-fund-performance.service';
import cumulativeChartDataQuery from '@graphql/fund-performance/cumulative-history.graphql';
import customCumulativeChartDataQuery from '@graphql/fund-performance/custom-cumulative-history.graphql';
import fundPerformanceCumulativeQuery from '@graphql/fund-performance/cumulative.graphql';
import fundPerformanceCumulativeSnapshotQuery from '@graphql/fund-performance/cumulative-snapshot.graphql';
import kebabCase from 'lodash/kebabCase';
import { FundOverviewService } from '@products/services/fund-overview.service';
import { TranslateService } from '@shared/translate/translate.service';
import { HighchartsThemeService } from '@frk/eds-components';
import { PEER_RANKINGS_KEY } from '@utils/app.constants';
import { ProfileService } from '@services';
import { takeUntil } from 'rxjs/operators';
import { PerformanceComponentId } from '../performance-component-id.enum';
import { toLocaleNumber } from '@utils/il8n/number-il8n';

const logger = Logger.getLogger('FundPerformanceCumulativeService');
@Injectable({
  providedIn: 'root',
})
export class FundPerformanceCumulativeService
  extends BaseFundPerformanceService
  implements OnDestroy {
  private state: FundPerformanceCumulativeState = {};
  private fundPerformanceStateSubject$: Subject<FundPerformanceCumulativeState>;
  private product: Product;
  private currentShareClass: ShareClass;
  private hideCurrentShareClassPerformanceData: boolean;
  private maxNumberOfBenchmarksToShow: number;
  private benchmarkOrder: string[];
  private suppressedBenchmarks: string[];
  private suppressedCumulative6m15y: boolean;
  private periodEnds: string[];
  private unsubscribe$: Subject<void> = new Subject<void>();
  isLoggedIn: boolean;

  constructor(
    private fundDetailService: FundDetailService,
    private config: SiteConfigService,
    private fundPerformanceHistoryService: FundPerformanceHistoryService,
    private fundOverviewService: FundOverviewService,
    translateService: TranslateService,
    protected highchartsTheme: HighchartsThemeService,
    protected profileService: ProfileService
  ) {
    super(translateService, highchartsTheme, profileService);
    this.fundPerformanceStateSubject$ = new Subject();
  }

  public get fundPerformanceState$(): Observable<FundPerformanceCumulativeState> {
    return this.fundPerformanceStateSubject$.asObservable();
  }

  /**
   * Call register method of fund detail service.
   */
  public populate(
    productDetailConfiguration: ProductDetailConfiguration
  ): void {
    this.resetState();

    this.isLoggedIn$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      });

    forkJoin([
      this.fundDetailService.register(fundPerformanceCumulativeQuery, {
        fundId: productDetailConfiguration.fundId,
      }),
      this.fundPerformanceHistoryService.register(cumulativeChartDataQuery, {
        fundId: productDetailConfiguration.fundId,
        shareClassCode: productDetailConfiguration.shareClassCode,
      }),
    ]).subscribe(
      ([product, perfHistorytData]: [Product, PerformanceHistory]) => {
        logger.debug(product);
        this.currentShareClass = this.findShareClassByShareClassCode(
          product,
          productDetailConfiguration.shareClassCode
        );
        if (
          this.currentShareClass &&
          this.isPerformanceDataAvailable(this.currentShareClass) &&
          perfHistorytData.cumulative
        ) {
          this.mapState(product, perfHistorytData.cumulative);
          this.state.isError = false;
        } else {
          logger.error(`No Fund Performance Cumulative data found for fund id ${productDetailConfiguration.fundId}
         and share class ${productDetailConfiguration.shareClassCode} `);
          this.state.isError = true;
        }
        this.fundPerformanceStateSubject$.next(this.state);
      },
      (error) => {
        logger.error(
          `Error in getting Fund Performance Cumulative data for fund id ${productDetailConfiguration.fundId}
               and share class ${productDetailConfiguration.shareClassCode} `,
          error
        );
        this.state.isError = true;
        this.fundPerformanceStateSubject$.next(this.state);
      }
    );
  }

  public populateCustomChartData(fromDate: string, toDate: string): void {
    this.fundPerformanceHistoryService
      .register(customCumulativeChartDataQuery, {
        fundId: this.product.fundId,
        shareClassCode: this.currentShareClass.identifiers.shareClassCode,
        fromDate,
        toDate,
      })
      .subscribe(
        (perfHistorytData: PerformanceHistory) => {
          if (perfHistorytData?.cumulative?._100?.custom?.fund?.length > 0) {
            this.state.data.monthEnd.graphData._100.custom = this.processPerformanceChartData(
              perfHistorytData.cumulative._100.custom,
              PerformanceView.MONTHLY
            );
            this.state.data.monthEnd.graphData._10K.custom = this.processPerformanceChartData(
              perfHistorytData.cumulative._10k.custom,
              PerformanceView.MONTHLY
            );
            this.state.data.quarterEnd.graphData._100.custom = this.processPerformanceChartData(
              perfHistorytData.cumulative._100.custom,
              PerformanceView.QUARTERLY
            );
            this.state.data.quarterEnd.graphData._10K.custom = this.processPerformanceChartData(
              perfHistorytData.cumulative._10k.custom,
              PerformanceView.QUARTERLY
            );
            this.fundPerformanceStateSubject$.next(this.state);
          } else {
            logger.error(`No Fund Performance Cumulative History custom data found for fund id ${this.product.fundId}
              and share class ${this.currentShareClass.identifiers.shareClassCode} `);
            this.state.config.hideChart = true;
          }
          this.fundPerformanceStateSubject$.next(this.state);
        },
        (error) => {
          logger.error(
            `Error in getting Fund Performance Cumulative custom data for fund id ${this.product.fundId}
               and share class ${this.currentShareClass.identifiers.shareClassCode} `,
            error
          );
          this.state.config.hideChart = true;
          this.fundPerformanceStateSubject$.next(this.state);
        }
      );
  }

  private resetState() {
    this.product = null;
    this.currentShareClass = null;
    this.hideCurrentShareClassPerformanceData = false;
    this.maxNumberOfBenchmarksToShow = null;
    this.benchmarkOrder = null;
    this.suppressedBenchmarks = null;
    this.suppressedCumulative6m15y = null;
    this.periodEnds = null;
  }

  populateHistoricalData(toDate: string) {
    this.fundOverviewService
      .register(fundPerformanceCumulativeSnapshotQuery, {
        fundId: this.product.fundId,
        shareClassCode: this.currentShareClass.identifiers.shareClassCode,
        toDate,
      })
      .subscribe(
        (product: Product) => {
          logger.debug(product);
          const historicalData = this.getPerformanceTableData(
            product.shareClasses[0]?.performance?.historical,
            product.shareClasses[0]?.benchmarkPerformance?.historical,
            product.fundId,
            product.shareClasses[0]?.shareClassCode
          );
          const inceptionDate = this.currentShareClass.performanceInceptionDate;
          const asOfDate = historicalData[0]?.asOfDate;
          this.state.data.historicalData = {
            tableData: historicalData,
            tableLabel: 'products.perf-date-selected',
            performanceDates: {
              inceptionDate,
              asOfDate,
            },
          };

          this.state.config.historicalPeriodEnd = PerformanceView.CUSTOM;
          this.fundPerformanceStateSubject$.next(this.state);
        },
        (error) => {
          logger.error(
            `Error in getting Fund Performance Cumulative historical data for fund id ${this.product.fundId}
             and share class ${this.currentShareClass.identifiers.shareClassCode} `,
            error
          );
        }
      );
  }

  /**
   * Map the fund performance response.
   * @param product received product performance details
   */
  mapState(product: Product, historyData: CumulativePerformanceHistory) {
    this.state.perfStatusCaveat = this.getPerfStatusCaveat(
      this.currentShareClass
    );
    this.state.heading = this.translateService.instant(
      'products.performance-cumulative-heading' +
        (this.config.getMifidAge(
          this.currentShareClass.performanceInceptionDateStd
        ) || '')
    );
    this.product = product;
    this.hideCurrentShareClassPerformanceData = this.config.mustHidePerformanceData(
      this.currentShareClass.performanceInceptionDateStd,
      this.isLoggedIn
    );
    this.maxNumberOfBenchmarksToShow = this.config.getMaxNumberOfBenchmarksToShow(
      this.product.fundId
    );
    this.benchmarkOrder = this.config.getBenchmarkOrder(
      this.product.productTaxonomy
    );
    this.suppressedBenchmarks = this.config.getSuppressedBenchmarks(
      this.product.productTaxonomy
    );
    this.suppressedCumulative6m15y = this.config.getSuppressed6m15Y(
      this.product.fundFamily
    );

    this.periodEnds = this.config.getPeriodEnds(
      this.product.productType,
      PerformanceType.CUMULATIVE
    );
    this.state.data = this.mapPerformanceDetailsToState();
    this.state.config = {};
    if (!this.hideCurrentShareClassPerformanceData) {
      this.mapChartData(historyData);
    }
    this.populateConfig();
  }

  private populateConfig() {
    this.state.config.shareClassCurrency = symbols.get(
      this.currentShareClass.shareClassCurrency
    );

    this.state.config.periodEnds = this.periodEnds;
    this.state.config.periodEnd = this.state.config.periodEnds[0];
    this.state.config.historicalPeriodEnd = this.state.config.periodEnds[0];
    this.state.config.showCurrency = !this.config.hidePerformanceCurrency(
      this.product.productTaxonomy
    );
    this.state.config.showMifid = this.hideCurrentShareClassPerformanceData;
    this.state.config.show1M3M6M = this.config.product.performance?.showYtd1M3M6M;
    this.state.config.showYtd = !this.config.product.performance
      ?.hideYTDForCumulativeAndCalendarYear;
    this.state.config.chartCalculationOptions = this.config.getCumulativeChartCalculationType();
    this.state.config.chartCalculation = this.state.config.chartCalculationOptions[0];

    this.setHideChartConfig();
    this.state.config.customHistory = this.config.product.performance
      ?.cumulativeHistorical
      ? true
      : false;
    this.state.config.showHistorical = this.config.product.performance
      ?.acHistorical
      ? true
      : false;
    this.state.config.show6m15yr = !this.suppressedCumulative6m15y;
  }

  private setHideChartConfig() {
    const suppressionSet: PerformanceSuppressionSet = this.config.getPerformanceComponentSuppressionSet(
      PerformanceComponentId.CUMULATIVE,
      this.product.fundId
    );

    if (
      suppressionSet ||
      this.hideCurrentShareClassPerformanceData ||
      this.state.config.chartCalculationOptions.length === 0
    ) {
      this.state.config.hideChart = true;
    }
  }

  setPeriodEnd(periodEnd: string) {
    this.state.config.periodEnd = periodEnd;
    this.fundPerformanceStateSubject$.next(this.state);
  }

  setHistoricalPeriodEnd(periodEnd: string) {
    this.state.config.historicalPeriodEnd = periodEnd;
    this.state.data.historicalData = null;
    this.fundPerformanceStateSubject$.next(this.state);
  }

  setChartCalculation(chartCalculation: string) {
    this.state.config.chartCalculation = chartCalculation;
    this.fundPerformanceStateSubject$.next(this.state);
  }

  mapPerformanceDetailsToState(): FundPerformanceCumulativeStateData {
    const monthEndPerformanceData = this.getCurrentShareClassPerformancePeriodEndData(
      this.currentShareClass.performance.monthEnd,
      this.currentShareClass.benchmarkPerformance.monthEnd,
      'products.month-end'
    );
    const quarterEndPerformanceData = this.getCurrentShareClassPerformancePeriodEndData(
      this.currentShareClass.performance.quarterEnd,
      this.currentShareClass.benchmarkPerformance.quarterEnd,
      'products.quarter-end'
    );
    if (
      !this.config.hideAdditionalShareClassLinks(this.product.productTaxonomy)
    ) {
      const allShareClassData = this.getAllShareClassesPerformancePeriodEndData();
      monthEndPerformanceData.allShareClassesTableData = allShareClassData
        .get(PerformanceView.MONTHLY)
        .sort((current, next) =>
          stringCompare(current.name, next.name, null, null, false)
        );
      quarterEndPerformanceData.allShareClassesTableData = allShareClassData
        .get(PerformanceView.QUARTERLY)
        .sort((current, next) =>
          stringCompare(current.name, next.name, null, null, false)
        );
    }
    return {
      fundName: this.product.fundName,
      monthEnd: monthEndPerformanceData,
      quarterEnd: quarterEndPerformanceData,
    };
  }

  private getAllShareClassesPerformancePeriodEndData(): Map<
    PerformanceView,
    FundPerformanceCumulativeTableData[]
  > {
    const allShareClassesData: Map<
      PerformanceView,
      FundPerformanceCumulativeTableData[]
    > = new Map();
    allShareClassesData.set(PerformanceView.MONTHLY, []);
    allShareClassesData.set(PerformanceView.QUARTERLY, []);
    this.product.shareClasses.forEach((shareclass) => {
      if (
        shareclass.identifiers.shareClassCode !==
        this.currentShareClass.identifiers.shareClassCode
      ) {
        const hidePerformanceData = this.config.mustHidePerformanceData(
          shareclass.performanceInceptionDateStd,
          this.isLoggedIn
        );
        const defaultCalcType = this.config.getDefaultCalcType(
          this.product.productType
        );
        if (shareclass.performance.monthEnd?.length > 0) {
          const performanceData = this.getPerformanceData(
            shareclass,
            defaultCalcType,
            hidePerformanceData,
            shareclass.performance.monthEnd
          );

          allShareClassesData
            .get(PerformanceView.MONTHLY)
            .push(performanceData);
        }
        if (shareclass.performance.quarterEnd?.length > 0) {
          const performanceData = this.getPerformanceData(
            shareclass,
            defaultCalcType,
            hidePerformanceData,
            shareclass.performance.quarterEnd
          );
          allShareClassesData
            .get(PerformanceView.QUARTERLY)
            .push(performanceData);
        }
      }
    });
    return allShareClassesData;
  }

  private getPerformanceData(
    shareclass: ShareClass,
    defaultCalcType: string,
    hidePerformanceData: boolean,
    performances: PerformanceDetail[]
  ) {
    let performance = performances[0];
    performances.forEach((perf) => {
      if (perf.calcTypeStd === defaultCalcType) {
        performance = perf;
      }
    });
    const performanceData = this.getSHTableRow(
      performance,
      shareclass,
      hidePerformanceData
    );
    performanceData.link = shareclass.link;
    return performanceData;
  }
  sortRowsOnName(
    performanceData: FundPerformanceCumulativeTableData[]
  ): FundPerformanceCumulativeTableData[] {
    return performanceData.sort((current, next) => {
      return stringCompare(current.name, next.name, null, null, false);
    });
  }

  private getCurrentShareClassPerformancePeriodEndData(
    performanceArray: PerformanceDetail[],
    benchmarkArray: BMPerformanceDetail[],
    label: string
  ): FundPerformanceCumulativePeriodEndData {
    const inceptionDate = this.currentShareClass.performanceInceptionDate;
    const inceptionDateStd = this.currentShareClass.performanceInceptionDateStd;
    const asOfDate = performanceArray[0]?.performanceAsOfDate;
    const asOfDateStd = performanceArray[0]?.performanceAsOfDateStd;

    // NGC-6135: filter bmArray to restrict duplicate entries.
    const filteredBenchmarkArray: BMPerformanceDetail[] = [];
    benchmarkArray.forEach((benchmarkObj) => {
      const index: number = filteredBenchmarkArray.findIndex(
        (filteredBenchmarkObj) =>
          filteredBenchmarkObj.benchmarkLabel === benchmarkObj.benchmarkLabel
      );

      if (index === -1) {
        filteredBenchmarkArray.push(benchmarkObj);
      }
    });

    const rows: FundPerformanceCumulativeTableData[] = this.getPerformanceTableData(
      performanceArray,
      filteredBenchmarkArray,
      this.product.fundId,
      this.currentShareClass.shareClassCode
    );

    return {
      tableData: rows,
      tableLabel: label,
      performanceDates: {
        asOfDate,
        asOfDateStd,
        inceptionDate,
        inceptionDateStd,
      },
    };
  }

  private getPerformanceTableData(
    performanceArray: PerformanceDetail[],
    benchmarkArray: BMPerformanceDetail[],
    fundId: FundId,
    shareClassCode: ShareClassCode
  ): FundPerformanceCumulativeTableData[] {
    const rows: FundPerformanceCumulativeTableData[] = [];
    let filteredPfArray = this.config.isSouthAfrica()
      ? this.filterPerformanceArrayByCurrencyCode(
          performanceArray,
          this.currentShareClass.shareClassCurrency
        )
      : performanceArray;

    // WDE-4201 remove POP rows for CPREIF and TOF (product type)
    if (
      this.config.hidePerformanceTableRowPop(
        shareClassCode,
        this.product.productType
      )
    ) {
      filteredPfArray = this.filterOutCalcType(filteredPfArray, 'POP');
    }

    this.addShareClassRows(filteredPfArray, rows);
    this.addBenchmarkRows(benchmarkArray, rows);
    this.addChartColors(rows);
    this.movePeerRankingsToEnd(rows);
    return rows;
  }

  private addShareClassRows(
    performanceArray: PerformanceDetail[],
    rows: FundPerformanceCumulativeTableData[]
  ) {
    if (performanceArray) {
      if (performanceArray.length > 0) {
        const defaultCalcType = this.config.getDefaultCalcType(
          this.product.productType
        );
        const defaultCalcTypeRow = performanceArray.find(
          (perf) => perf.calcTypeStd === defaultCalcType
        );

        if (defaultCalcTypeRow) {
          performanceArray.splice(
            performanceArray.indexOf(defaultCalcTypeRow),
            1
          );
          performanceArray.unshift(defaultCalcTypeRow);
        }
        performanceArray.forEach((perf: PerformanceDetail) => {
          if (perf.calcTypeStd !== PEER_RANKINGS_KEY) {
            // Filter peer Rankings from performance data
            rows.push(
              this.getSHTableRow(
                perf,
                this.currentShareClass,
                this.hideCurrentShareClassPerformanceData
              )
            );
          }
        });
      }
    }
  }

  private addBenchmarkRows(
    benchmarkArray: BMPerformanceDetail[],
    rows: FundPerformanceCumulativeTableData[]
  ) {
    if (benchmarkArray) {
      if (this.maxNumberOfBenchmarksToShow !== BenchmarksToShow.ZERO) {
        benchmarkArray.sort((bm1, bm2) =>
          this.compareBenchmarks(bm1, bm2, this.benchmarkOrder)
        );
      }
      benchmarkArray = benchmarkArray.slice(
        0,
        this.maxNumberOfBenchmarksToShow
      );

      benchmarkArray.forEach(
        (benchmark: BMPerformanceDetail, index: number) => {
          if (benchmark.calcTypeStd === PEER_RANKINGS_KEY) {
            // Display peer rankings only for funds for which it has been configured to show
            if (
              this.config.showPeerGroupCategoryAverageAddition(
                this.product.productTaxonomy
              )
            ) {
              const bmRow = this.getBMTableRow(
                benchmark,
                rows[0],
                this.suppressedBenchmarks.includes(benchmark.benchmarkLabel),
                index
              );
              rows.push(bmRow);
            }
          } else {
            const bmRow = this.getBMTableRow(
              benchmark,
              rows[0],
              this.suppressedBenchmarks.includes(benchmark.benchmarkLabel),
              index
            );
            rows.push(bmRow);
          }
        }
      );
    }
  }

  private movePeerRankingsToEnd(
    rows: FundPerformanceCumulativeTableData[]
  ): void {
    if (rows?.length) {
      const index: number = rows.findIndex(
        (row: FundPerformanceCumulativeTableData) => row.isPeerRankingsRow
      );
      rows.push(rows.splice(index, 1)[0]);
    }
  }

  private getSHTableRow(
    performanceData: PerformanceDetail,
    shareClass: ShareClass,
    hideShareClassPerformanceData: boolean,
    hideOnChart: boolean = false
  ): FundPerformanceCumulativeTableData {
    const basicShareClass = this.extractBasicShareClassFromPerformanceRow(
      performanceData,
      this.product.fundName,
      shareClass
    );
    const yearToDate = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.cummulativeReturnYearToDate;
    const oneMonth = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.cummulativeReturn1Month;
    const threeMonth = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.cummulativeReturn3Month;
    const sixMonth = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.cummulativeReturn6Month;
    const oneYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.cummulativeReturn1Year;
    const threeYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.cummulativeReturn3Year;
    const fiveYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.cummulativeReturn5Year;
    const tenYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.cummulativeReturn10Year;
    const fifteenYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.cummulativeReturn15Year;
    const sinceInception = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.cummulativeReturnSinceInception;

    const fundPerformanceTableData: FundPerformanceCumulativeTableData = Object.assign(
      basicShareClass,
      {
        yearToDate,
        oneMonth,
        threeMonth,
        sixMonth,
        oneYear,
        threeYear,
        fiveYear,
        tenYear,
        fifteenYear,
        sinceInception,
        asOfDate: performanceData.performanceAsOfDate,
        hideOnChart,
        placement: BaseFundPerformanceService.fundPerformancePlacement,
        calcTypeLabel: `products.${this.getProductTypeLabel(
          this.product.productType
        )}-performance-calctype-${kebabCase(performanceData.calcTypeStd)}`,
        calcName: performanceData.calcTypeStd,
      }
    );
    return fundPerformanceTableData;
  }

  private getBMTableRow(
    bmPerformanceData: BMPerformanceDetail,
    fundRow: FundPerformanceCumulativeTableData,
    suppressBMData: boolean,
    index: number
  ): FundPerformanceCumulativeTableData {
    const name = bmPerformanceData.benchmarkName;
    const yearToDate =
      suppressBMData && this.isDataNotAvailable(fundRow.yearToDate)
        ? EMDASH
        : bmPerformanceData.cummulativeReturnYearToDate;
    const oneMonth =
      suppressBMData && this.isDataNotAvailable(fundRow.oneMonth)
        ? EMDASH
        : bmPerformanceData.cummulativeReturn1Month;
    const threeMonth =
      suppressBMData && this.isDataNotAvailable(fundRow.threeMonth)
        ? EMDASH
        : bmPerformanceData.cummulativeReturn3Month;
    const sixMonth =
      suppressBMData && this.isDataNotAvailable(fundRow.sixMonth)
        ? EMDASH
        : bmPerformanceData.cummulativeReturn6Month;
    const oneYear =
      suppressBMData && this.isDataNotAvailable(fundRow.oneYear)
        ? EMDASH
        : bmPerformanceData.cummulativeReturn1Year;
    const threeYear =
      suppressBMData && this.isDataNotAvailable(fundRow.threeYear)
        ? EMDASH
        : bmPerformanceData.cummulativeReturn3Year;
    const fiveYear =
      suppressBMData && this.isDataNotAvailable(fundRow.fiveYear)
        ? EMDASH
        : bmPerformanceData.cummulativeReturn5Year;
    const tenYear =
      suppressBMData && this.isDataNotAvailable(fundRow.tenYear)
        ? EMDASH
        : bmPerformanceData.cummulativeReturn10Year;
    const fifteenYear =
      suppressBMData && this.isDataNotAvailable(fundRow.fifteenYear)
        ? EMDASH
        : bmPerformanceData.cummulativeReturn15Year;

    return {
      name,
      yearToDate,
      oneMonth,
      threeMonth,
      sixMonth,
      oneYear,
      threeYear,
      fiveYear,
      tenYear,
      fifteenYear,
      sinceInception: bmPerformanceData.cummulativeReturnSinceInception,
      asOfDate: bmPerformanceData.performanceAsOfDate,
      inceptionDate: EMDASH,
      currency: bmPerformanceData.currencyCode,
      placement: this.getBemchmarkPerformancePlacement(index),
      hideOnChart: index > 1,
      isPeerRankingsRow: bmPerformanceData.calcTypeStd === PEER_RANKINGS_KEY,
    };
  }

  private mapChartData(historyData: CumulativePerformanceHistory) {
    this.state.data.monthEnd.graphData = {
      _100: this.getHistoryData(
        historyData,
        ChartCalculationType.HUNDRED_REBASE_POINTS,
        PerformanceView.MONTHLY
      ),
      _10K: this.getHistoryData(
        historyData,
        ChartCalculationType.TENK_GROWTH,
        PerformanceView.MONTHLY
      ),
    };

    this.state.data.quarterEnd.graphData = {
      _100: this.getHistoryData(
        historyData,
        ChartCalculationType.HUNDRED_REBASE_POINTS,
        PerformanceView.QUARTERLY
      ),
      _10K: this.getHistoryData(
        historyData,
        ChartCalculationType.TENK_GROWTH,
        PerformanceView.QUARTERLY
      ),
    };

    this.state.data.quarterEnd.performanceDateRange = {
      minDate: this.state.data.quarterEnd.performanceDates.inceptionDateStd,
      maxDate: this.state.data.quarterEnd.performanceDates.asOfDateStd,
    };
    this.state.data.monthEnd.performanceDateRange = {
      minDate: this.state.data.monthEnd.performanceDates.inceptionDateStd,
      maxDate: this.state.data.monthEnd.performanceDates.asOfDateStd,
    };

    const oneYearData = this.state.data.monthEnd.graphData[
      ChartCalculationType.HUNDRED_REBASE_POINTS
    ].oneYear;
    if (
      !oneYearData ||
      oneYearData.length === 0 ||
      oneYearData[0].length === 0
    ) {
      this.state.config.hideChart = true;
    }
  }

  private addChartConfigToRows(
    rows: FundPerformanceCumulativeTableData[],
    fundData: FundCumulativePerformanceHistory[]
  ) {
    rows.forEach((element) => {
      if (element.calcName) {
        element.hideOnChart =
          fundData?.filter((fundDataObj) => {
            return fundDataObj.calcName === element.calcName;
          }).length === 0;
      }
    });
  }

  private getGraphNames(
    tableData: FundPerformanceCumulativeTableData[]
  ): string[] {
    return tableData
      .filter((row) => !row?.hideOnChart)
      .map((row) => {
        const label = row.calcTypeLabel;
        const suffix = label ? ` ${this.translateService.instant(label)}` : '';
        return `${row.name}${suffix}`;
      });
  }

  private getHistoryData(
    historyData: CumulativePerformanceHistory,
    chartCalculation: ChartCalculationType,
    performanceView: PerformanceView
  ): FundPerformanceCumulativeGraphData {
    const timePeriod =
      chartCalculation === ChartCalculationType.HUNDRED_REBASE_POINTS
        ? historyData._100
        : historyData._10k;
    const state: FundPerformanceCumulativeGraphData = {
      oneYear: this.processPerformanceChartData(
        timePeriod._1yr,
        performanceView
      ),
      threeYear: this.processPerformanceChartData(
        timePeriod._3yr,
        performanceView
      ),
      fiveYear: this.processPerformanceChartData(
        timePeriod._5yr,
        performanceView
      ),
      tenYear: this.processPerformanceChartData(
        timePeriod._10yr,
        performanceView
      ),
      fifteenYear: this.processPerformanceChartData(
        timePeriod._15yr,
        performanceView
      ),
      sinceInception: this.processPerformanceChartData(
        timePeriod.sinceInception,
        performanceView
      ),
    };
    return state;
  }

  private processPerformanceChartData(
    category: CumulativePerformanceHistoryCategory,
    performanceView: PerformanceView
  ) {
    if (category.fund.length > 0) {
      try {
        const startOfQuarterMillis = getStartOfQuarterInMilliseconds();
        const fundData =
          performanceView === PerformanceView.QUARTERLY
            ? category.fund.filter((fundDataObj) =>
                this.isStdDateBeforeThanGivenTimestamp(
                  fundDataObj.asOfDateStd,
                  startOfQuarterMillis
                )
              )
            : category.fund;
        if (performanceView === PerformanceView.QUARTERLY) {
          this.addChartConfigToRows(
            this.state.data.quarterEnd.tableData,
            fundData
          );
        } else {
          this.addChartConfigToRows(
            this.state.data.monthEnd.tableData,
            fundData
          );
        }

        const primaryBm =
          performanceView === PerformanceView.QUARTERLY
            ? category.benchmark.primary.filter((bmObj) =>
                this.isStdDateBeforeThanGivenTimestamp(
                  bmObj.asOfDateStd,
                  startOfQuarterMillis
                )
              )
            : category.benchmark.primary;
        const secondaryBm =
          performanceView === PerformanceView.QUARTERLY
            ? category.benchmark.secondary.filter((bmObj) =>
                this.isStdDateBeforeThanGivenTimestamp(
                  bmObj.asOfDateStd,
                  startOfQuarterMillis
                )
              )
            : category.benchmark.secondary;

        return this.createChartSeries(
          fundData,
          primaryBm,
          secondaryBm,
          performanceView
        );
      } catch (e) {
        logger.debug('Cumulative Error: ' + e);
        return [];
      }
    }
    return [];
  }

  private isStdDateBeforeThanGivenTimestamp(
    date: string,
    timestamp: number
  ): unknown {
    return getTimeStampInMilliseconds(date, 'YYYY-MM-DD') < timestamp;
  }

  private createChartSeries(
    fundData: FundCumulativePerformanceHistory[],
    primaryBM: BMCumulativePerformanceHistory[],
    secondaryBM: BMCumulativePerformanceHistory[],
    performanceView: PerformanceView
  ) {
    const chartRows = [];
    if (performanceView === PerformanceView.QUARTERLY) {
      this.state.data.quarterEnd.tableData?.forEach((ele) => {
        if (!ele.hideOnChart && ele.calcName) {
          chartRows.push(this.processDataForChart(fundData, ele.calcName));
        }
      });
      this.state.data.quarterEnd.graphNames = this.getGraphNames(
        this.state.data.quarterEnd.tableData
      );
      // update chart colors once we determine which rows to hide on chart.
      this.addChartColors(this.state.data.quarterEnd.tableData);
    } else {
      this.state.data.monthEnd.tableData?.forEach((ele) => {
        if (!ele.hideOnChart && ele.calcName) {
          chartRows.push(this.processDataForChart(fundData, ele.calcName));
        }
      });
      this.state.data.monthEnd.graphNames = this.getGraphNames(
        this.state.data.monthEnd.tableData
      );
      // update chart colors once we determine which rows to hide on chart.
      this.addChartColors(this.state.data.monthEnd.tableData);
    }

    // Note: benchmarks order can be empty or can be in any order, but history only provides for inst1 and inst2.
    const sortedBenchmarkOrder = this.benchmarkOrder.filter(
      (benchmark) => benchmark === 'INST1' || benchmark === 'INST2'
    );
    if (this.maxNumberOfBenchmarksToShow > 0) {
      if (sortedBenchmarkOrder[0] === 'INST2' && secondaryBM.length > 0) {
        chartRows.push(this.processDataForChart(secondaryBM));
      } else {
        chartRows.push(this.processDataForChart(primaryBM));
      }
    }
    if (this.maxNumberOfBenchmarksToShow > 1) {
      if (sortedBenchmarkOrder[0] === 'INST2' && primaryBM.length > 0) {
        chartRows.push(this.processDataForChart(primaryBM));
      } else {
        chartRows.push(this.processDataForChart(secondaryBM));
      }
    }
    return chartRows;
  }

  private processDataForChart(
    data: (FundCumulativePerformanceHistory | BMCumulativePerformanceHistory)[],
    calcName?: string
  ): any[] {
    let filteredData = data;
    if (calcName) {
      filteredData = data?.filter(
        (element: FundCumulativePerformanceHistory) => {
          return element.calcName === calcName;
        }
      );
    }

    const chartData = filteredData?.map((detail) => {
      let seperator;
      const convertedDate = getTimeStampInMilliseconds(
        detail.asOfDateStd,
        'YYYY-MM'
      );

      const cumulativeMonthlyReturnCalculated = toLocaleNumber(
        Number(detail.cumulativeMonthlyReturnCalculatedStd)
      );
      seperator = cumulativeMonthlyReturnCalculated.includes(',') ? ',' : '.';
      return [
        convertedDate,
        seperator,
        parseNumber(cumulativeMonthlyReturnCalculated),
      ];
    });
    return chartData?.sort((a, b) => a[0] - b[0]);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
