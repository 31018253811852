import { SpinnerDetails } from '@accounts/accounts-alerts/accounts-alerts.interface';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Location as LocationNg } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AdditionalLegacy,
  AdditionalLegacyLink,
  AnalyticsService,
  DialogConfig,
  DialogService,
  NavigationService,
  SignInComponentFieldsInfo,
  SignInEvent,
} from '@frk/eds-components';
// TODO: this import can cause circular dependencies
// as in comments below, cart service needs refactored to watch for sign out events
import { CartHandlerService } from '@literature/services/cart-handler.service';
import { LOCATION, WINDOW } from '@ng-web-apis/common';
import {
  AnalyticsLogin,
  AuthResponse,
  HiddenInput,
  ModalIntroTxt,
  SignInInterface,
  StaticPassAccessConfig,
  StaticPassAccessUser,
} from '@shared/sign-in/sign-in.interface';
import { TranslateService } from '@shared/translate/translate.service';
import {
  AUTH_RESPONSE,
  IS_MFA_COMPLETE,
  LOCATION_LABL,
  MARKETING_FLAG,
  NEW,
  NEW_AUTH_SESSION,
  SIGNIN_PIN,
  SIGNIN_REDIRECTTOURL,
  SIGNIN_REMEMBERME,
  SIGNIN_SPINNER,
  SIGNIN_USERID,
  TEMP_USER_COOKIE,
  TOKEN_EXPIRY,
  UPGRADE_NOTNOW,
  USER_GROUP,
} from '@utils/app.constants';
import { CustomHttpParamEncoder } from '@utils/custom-http-param-encoder';
import { removeParam } from '@utils/link-utils';
import { Logger } from '@utils/logger';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Segment, SegmentId } from '../types/segment.type';
import { AppStateService } from './app-state.service';
import {
  AccountsAccess,
  IUserProfileInfo,
  LoginSource,
  ProfileSummary,
} from './profile.interface';
import { ProfileService } from './profile.service';
import { SegmentService } from './segment.service';
import { SessionService } from './session.service';
import { SiteConfigService } from './site-config.service';
import { StorageService } from './storage.service';
import { defaultIfEmpty, switchMap, take } from 'rxjs/operators';
import { PersonalisationAPIService } from './personalisation-api.service';
import { GlobalId, PersonalisationPersonalData } from '@types';

const logger = Logger.getLogger('SignInService');
const dpxcentralContainerId = 'GTM-TH6LQGG';

const serviceCenter = 'service-center';

@Injectable({
  providedIn: 'root',
})
export class SignInService {
  private spinnerDetails: SpinnerDetails = {
    spinnerName: SIGNIN_SPINNER,
    spinnerLoadingText: this.translateService.instant('signin.signinSpinner'),
  };

  private showSignInModalSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private showSignInCustomIntroModalSubject$: BehaviorSubject<ModalIntroTxt> = new BehaviorSubject<ModalIntroTxt>(
    { customLabel: '', showMsg: false }
  );

  private showSignInCustomContentModalSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  private signInModalTitle$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  private readonly spinner$: Subject<SpinnerDetails>;
  private reloadingPage$: Subject<boolean>;

  private isPagePRC = false;

  private callbackCalled = false;

  private staticPassAccessConfig$: Subject<StaticPassAccessConfig> = new Subject();

  private emailId?: string;

  private parentFirmGlobalId?: GlobalId;

  // prettier-ignore
  constructor( // NOSONAR - typescript:S107 - we need to accept more than 7 parameters in the constructor.
    @Inject(WINDOW) readonly windowRef: Window,
    @Inject(LOCATION) readonly locationRef: Location,
    private sessionService: SessionService,
    private cartService: CartHandlerService,
    private appStateService: AppStateService,
    private analyticsService: AnalyticsService,
    private storageService: StorageService,
    private segmentService: SegmentService,
    private translateService: TranslateService,
    private profileService: ProfileService,
    private navService: NavigationService,
    private siteConfig: SiteConfigService,
    private locationNg: LocationNg,
    private dialogService: DialogService,
    private personalisationService: PersonalisationAPIService
  ) {
    this.spinner$ = new Subject<SpinnerDetails>();
    this.reloadingPage$ = new Subject<boolean>(); // to inform other services that the page is about to reload
    this.personalisationService.getPersonalData$()
    .pipe(take(1))
    .subscribe((personalData: PersonalisationPersonalData) => {
      this.emailId = personalData.email;
      this.parentFirmGlobalId = personalData.parentFirmGlobalId;
    });
  }

  public getSpinner$(): Subject<SpinnerDetails> {
    return this.spinner$;
  }

  public getReloadingPage$(): Subject<boolean> {
    return this.reloadingPage$;
  }

  /**
   * Function to post sign in API call.
   */
  public onSubmit(
    submitObject: AnalyticsLogin,
    downloadLocation?: string
  ): void {
    logger.debug('onSubmit for Signin', submitObject);
    this.spinnerDetails.spinnerLoadingText = this.translateService.instant(
      'signin.signinSpinner'
    );
    this.spinnerDetails.spinnerStatus = true;
    this.spinner$.next(this.spinnerDetails);
    // US Accounts - MyFunds and Canada goes direct
    this.locationNg.replaceState(
      this.removeParamsFromUrl(
        // WDE-3495 add url hash if it exists too
        this.locationRef.pathname +
          this.locationRef.search +
          this.locationRef.hash
      )
    );
    if (this.appStateService.isOktaLogin()) {
      this.submitOkta(submitObject);
    } else if (this.appStateService.isAuth0Login()) {
      this.submitAuth0();
    } else {
      this.onSubmitUs(submitObject, downloadLocation);
    }
  }

  public trackLogin(event: Event): void {
    if (this.appStateService.getAuthenticationType() === 'canada') {
      // NGC-15359 - login tracking for Canada
      const target = event.target as HTMLElement;
      const isLogInButton =
        (target instanceof HTMLButtonElement ||
          target.parentElement instanceof HTMLButtonElement) &&
        (target.classList.contains('sign-in__sign-in-btn') ||
          target.parentElement.classList.contains('sign-in__sign-in-btn'));

      if (isLogInButton) {
        this.analyticsService.trackEvent({
          event: 'login',
          name: 'login',
          method: 'site',
          page_location: this.windowRef.location.href,
        });

        this.analyticsService.trackEvent({
          event: 'login',
          detailed_event: 'User Signed In',
          event_data: {
            name: 'login',
            method: 'site',
          },
        });
      }
    }
  }

  public getHiddenInputs(noRedirect?: boolean): [HiddenInput?, HiddenInput?] {
    if (
      // TODO this if clause can be removed after Myfunds FIS launch
      this.appStateService.getAuthenticationType() === 'myfunds' &&
      !this.siteConfig.hideLoginForm()
    ) {
      return [
        { name: 'locale', value: this.appStateService.getMyFundsLocale() },
        {
          name: 'segment',
          value:
            this.segmentService.getCurrentSegmentId() === SegmentId.INVESTOR
              ? 'INVESTOR'
              : 'ADVISOR',
        },
      ];
    }
    if (this.appStateService.getAuthenticationType() === 'canada') {
      if (noRedirect) {
        return [{ name: 'sign-in-or-register', value: 'sign-in-or-register' }];
      }
      return [
        { name: 'sign-in-or-register', value: 'sign-in-or-register' },
        {
          name: 'redirectToURL',
          value: this.removeParamsFromUrl(this.locationRef.href),
        },
      ];
    }
    return [];
  }

  public getLoginUrl(): string {
    return this.sessionService.getLoginUrl();
  }

  private onSubmitUs(
    submitObject: AnalyticsLogin,
    downloadLocation?: string
  ): void {
    logger.debug('Entering onSubmitUs', submitObject);
    const signInForm: FormGroup = submitObject.signInForm;

    const activeDownloadLocationKey = 'current_download_location';

    this.isPagePRC = this.locationRef.pathname.includes(serviceCenter); // to check if the page is service center

    const loginFormData = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    })
      .set(SIGNIN_USERID, signInForm?.value.userId)
      .set(SIGNIN_PIN, signInForm?.value.password)
      .set(SIGNIN_REMEMBERME, signInForm?.value?.rememberMe)
      .set(SIGNIN_REDIRECTTOURL, this.locationRef.href)
      .set(MARKETING_FLAG, NEW);

    this.analyticsService.trackEvent({
      event: 'login_attempt',
      location: submitObject.analyticsKey,
      link_url: this.getLoginUrl(),
    });

    if (
      downloadLocation &&
      downloadLocation !== 'undefined' &&
      downloadLocation !== '#'
    ) {
      this.storageService.store<string>(
        activeDownloadLocationKey,
        downloadLocation
      );
    }

    logger.debug('Triggering Authenticate Service for US', submitObject);
    this.sessionService.authenticateUser$(loginFormData.toString()).subscribe(
      // TODO: refactor success callback as separate private function
      (response: HttpResponse<any>) => {
        if (
          response &&
          response.status === 201 &&
          response.headers.get(LOCATION_LABL)
        ) {
          if (this.storageService.getDeviceRecognizeStatus()) {
            const body: AuthResponse = {
              redirectiveUrl: response.headers.get(LOCATION_LABL),
              userId: signInForm?.value.userId,
              userSysNo: this.storageService.getUserSysNumber(),
              deviceRecognizedYN: this.storageService.getDeviceRecognizeStatus(),
              securityQuestionsFlag: this.storageService.getSecurityQuestionStatus(),
              role: this.profileService.getUserProfile().profileInfo?.role,
              webExperience: this.profileService.getUserProfile().profileInfo
                ?.webExperience,
            };
            // in this scenario we may receive login fail redirect or mfa redirect
            // according to Syed, there is no other way to determine which answer received
            const eventName = body?.redirectiveUrl?.endsWith(
              '/profile/signin/fail'
            )
              ? 'login_fail'
              : 'mfa';

            if (body.deviceRecognizedYN === 'N') {
              // Mapping profile summary to store it in case profile is set from bypass
              const profileSummaryFromResponse = this.mapProfileSummaryFromResponse(
                body
              );
              // We need to store correct session on marketing side before reloading the page.
              this.storageService.storeProfileSummary(
                profileSummaryFromResponse
              );
              this.callbackCalled = false;
              this.trackEvent(eventName, (gtmId: string) => {
                if (
                  gtmId !== this.appStateService.getGtmId() &&
                  gtmId !== dpxcentralContainerId
                ) {
                  return;
                }
                this.callbackCalled = true;
                this.redirectToMFA(body);
              });
            }

            if (body.deviceRecognizedYN === 'Y') {
              this.callbackCalled = false;
              this.trackEvent(eventName, (gtmId: string) => {
                if (
                  gtmId !== this.appStateService.getGtmId() &&
                  gtmId !== dpxcentralContainerId
                ) {
                  return;
                }
                this.callbackCalled = true;
                this.navService.navigateByUrl(
                  response.headers.get(LOCATION_LABL),
                  '_self'
                );
              });
            }
          } else {
            this.callbackCalled = false;
            this.trackEvent('login_fail', (gtmId: string) => {
              // execute callback only if GTM container id is main
              // sometimes there may be more than one container id on the page
              if (
                gtmId !== this.appStateService.getGtmId() &&
                gtmId !== dpxcentralContainerId
              ) {
                return;
              }
              // we set this to true after callback is called from GTM (anaytics)
              // otherwise it will be called from setTimeout method inside trackEvent()
              this.callbackCalled = true;

              logger.debug(
                'Redirecting Profile App Flows with 201 Status',
                response
              );

              this.navService.navigateByUrl(
                this.getRedirectURL(response.headers.get(LOCATION_LABL), false),
                '_self'
              );
            });
          }
        } else {
          // Mapping profile summary to store it in case profile is set from bypass
          const profileSummaryFromResponse = this.mapProfileSummaryFromResponse(
            response.body
          );
          // We need to store correct session on marketing side before reloading the page.
          this.storageService.storeProfileSummary(profileSummaryFromResponse);
          // Check if device not recognized redirect to MFA
          if (response.body.deviceRecognizedYN === 'N') {
            this.callbackCalled = false;
            this.trackEvent('mfa', (gtmId: string) => {
              if (
                gtmId !== this.appStateService.getGtmId() &&
                gtmId !== dpxcentralContainerId
              ) {
                return;
              }
              this.callbackCalled = true;
              this.storageService.removeIdentyToken();
              this.redirectToMFA(response?.body);
            });
          }

          // Check if device is recognized
          if (response.body.deviceRecognizedYN === 'Y') {
            // we call GTM callback to proceed
            this.callbackCalled = false;
            this.trackEvent('login', (gtmId: string) => {
              // execute callback only if GTM container id is main
              // sometimes there may be more than one container id on the page
              if (
                gtmId !== this.appStateService.getGtmId() &&
                gtmId !== dpxcentralContainerId
              ) {
                return;
              }
              // we set this to true after callback is called from GTM (anaytics)
              // otherwise it will be called from setTimeout method inside trackEvent()
              this.callbackCalled = true;

              logger.debug('US Login Success for ', response?.body?.userSysNo);
              this.storageService.storeIsLoggedInSession(true);

              // sets segment based on mapped profile user
              this.saveSegmentIntoStorage(response.body.role);

              // Login in from Home page and dashboardUrl is available means, need to redirect accounts dashboard.
              const isHomePage = this.appStateService.isHomePage();

              if (
                isHomePage &&
                response.body.accountsAccess === AccountsAccess.ACCESS &&
                this.profileService.getSegmentRole(response.body.role) ===
                  SegmentId.INVESTOR &&
                response.body.dashboardUrl
              ) {
                logger.debug(
                  'Redirecting Dashboard for Accounts User',
                  response?.body?.userSysNo
                );
                this.navService.navigateByUrl(
                  response.body.dashboardUrl,
                  '_self'
                );
              } else {
                this.reloadingPage$.next(true);
                logger.debug(
                  'Load the marketing page',
                  response?.body?.userSysNo
                );
                if (
                  isHomePage &&
                  this.profileService.getSegmentRole(response.body.role) ===
                    SegmentId.FINANCIAL_PROFESSIONALS
                ) {
                  this.windowRef.location.href = response.body.targetUrl;
                } else {
                  // Remove params from URL during login procedure
                  this.windowRef.location.href = this.removeParamsFromUrl(
                    this.locationRef.href
                  );
                }
                this.storageService.removeIdentyToken();
                this.windowRef.location.reload();
              }
            });
          }
        }
      },
      (exception: any) => {
        this.spinnerDetails.spinnerStatus = false;
        this.spinner$.next(this.spinnerDetails);
        logger.error('Login connection issue:', exception);

        this.analyticsService.trackEvent({
          event: 'login_error',
          detailed_event: 'User Sign In Errored',
          event_data: {
            method: 'site',
            name: 'login_error',
          },
        });
      }
    );
  }

  private submitOkta(submitObject: AnalyticsLogin): void {
    logger.debug('Entering onSubmitOkta', submitObject);
    const signInForm: FormGroup = submitObject.signInForm;
    const oktaClient = this.sessionService.getOktaClient(
      this.appStateService.getAuthenticationType()
    );
    this.storageService.saveOktaCallbackRoute(this.locationRef.pathname);
    oktaClient
      .signInWithCredentials({
        username: signInForm?.value.userId,
        password: signInForm?.value.password,
      })
      .then((transaction) => {
        if (transaction.status === 'SUCCESS') {
          oktaClient.token.getWithRedirect({
            sessionToken: transaction.sessionToken,
            responseType: 'id_token',
          });
        } else {
          logger.info('Invalid credentials');
        }
      });
  }

  public submitAuth0(hint?: string, returnUri?: string): void {
    this.sessionService.auth0Authorize(
      {
        ui_locales: this.appStateService.getHtmlLang(),
        screen_hint: hint,
        login_hint: this.emailId,
        // if we have to pass custom param to universal template
        // we have to add 'ext-' prefix in custom param
        'ext-firmId': this.parentFirmGlobalId,
      },
      returnUri
    );
  }

  /**
   *
   */
  private saveSegmentIntoStorage(role: string): void {
    const profileSegmentId: SegmentId = this.profileService.getSegmentRole(
      role
    );
    this.segmentService.setSegment(profileSegmentId, true);
  }
  /**
   * Set Profile Summary from Accounts service response
   * @param profileBodyResponse - response body
   */
  private mapProfileSummaryFromResponse(
    profileBodyResponse: IUserProfileInfo
  ): ProfileSummary {
    return {
      role: profileBodyResponse.role,
      source: LoginSource.OAUTH,
      webExperience: profileBodyResponse.webExperience,
      firm: profileBodyResponse.firm,
      accountsAccess: profileBodyResponse.accountsAccess,
      dashboardUrl: profileBodyResponse.dashboardUrl,
      // isLoggedIn: true,
    };
  }

  /**
   * Function to make sign out API call.
   */
  public signOut(segment?: Segment): void {
    this.storageService.remove(UPGRADE_NOTNOW);
    this.storageService.remove(NEW_AUTH_SESSION);
    this.storageService.remove(USER_GROUP, true);
    this.storageService.removeAuthResponseCookie();
    this.storageService.removeMFAStatusCookie();
    this.cartService.emptyCartStorage(); // FIXME cart should watch profile instead.
    this.isPagePRC = this.locationRef.pathname.includes(serviceCenter); // to check if the page is service center
    this.trackEvent('logout');
    // We need to remove profile summary during logout to cleanup user experience.
    this.storageService.removeProfileSummary();
    // NGC-11585 - Need to remove bypass cookie during sign-out.
    this.storageService.removeBypassCookie();
    this.storageService.removePcsCookie();
    // NGC-8740 - Change logged in session during logout process to avoid loading resourceapi 2 times.
    this.storageService.storeIsLoggedInSession(false);
    this.storageService.saveAuth0CallbackRoute(this.locationRef.pathname);
    this.sessionService.logout(segment);
    // TODO: should this be updating the ProfileService so it knows the user is logged out?
  }

  private trackEvent(type: string, callback?: any): void {
    // UA to remove after migration to GA4
    const trackObject = {
      event: type,
      name: type,
      method: 'site',
      page_location: this.windowRef.location.href,
    };

    let detailedEvent = '';
    let typeGA4 = type;
    let method = 'site';

    switch (type) {
      case 'login':
        method = this.isPagePRC ? 'PRC' : 'site';
        detailedEvent = 'User Signed In';
        break;
      case 'mfa':
        typeGA4 = 'login';
        method = this.isPagePRC ? 'PRC' : 'mfa';
        detailedEvent = 'User Signed In';
        break;
      case 'logout':
        detailedEvent = 'User Signed Out';
        method = this.isPagePRC ? 'PRC' : 'site';
        break;
      case 'login_fail':
        typeGA4 = 'login_error';
        method = this.isPagePRC ? 'PRC' : 'site';
        detailedEvent = 'User Sign In Errored';
        break;
    }
    const trackObjectGA4 = {
      event: typeGA4,
      detailed_event: detailedEvent,
      event_data: {
        name: typeGA4,
        method,
      },
    };

    this.storageService
      .retrieve<boolean>(TOKEN_EXPIRY, true)
      .then((wasUserLoggedInBefore) => {
        /**
         * If a user was logged out due to token expiry and tries
         * to login again in the same session change the analytics event.
         * to re-authentication
         * TODO add GA4 tracking code?
         */
        if (wasUserLoggedInBefore) {
          if (type === 'login') {
            trackObject.event = 're-authentication';
            trackObject.event = 're-authentication';
          }
          if (type === 'login_fail') {
            trackObject.event = 're-authentication_fail';
            trackObject.event = 're-authentication_fail';
          }
        }

        const that = this;

        setTimeout(() => {
          if (callback && !that.callbackCalled) {
            that.callbackCalled = true;
            callback(this.appStateService.getGtmId());
          }
        }, 2000);

        // UA to remove after migration to GA4
        this.analyticsService.trackEvent(trackObject, callback);

        // GA4 call
        this.analyticsService.trackEvent(trackObjectGA4, callback);
        this.storageService.remove(TOKEN_EXPIRY, true);
      });
  }

  /**
   * Function to initialize sign in form.
   */
  public signInFormInit(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      userId: ['', [Validators.required]],
      password: ['', [Validators.required]],
      // Commented now according to NGC-8586
      // rememberMe: [false],
    });
  }

  /**
   * Set SignIn Modal Subject
   */
  public getSignInModal$(): Observable<boolean> {
    return this.showSignInModalSubject$.asObservable();
  }

  /**
   * Set SignIn Custom Intro Txt Modal Subject
   */
  public getSignInCustomIntroTxtModal$(): Observable<ModalIntroTxt> {
    return this.showSignInCustomIntroModalSubject$.asObservable();
  }

  /**
   * Get SignIn Custom Content Label for Modal Subject
   */
  public getSignInCustomContentModal$(): Observable<string> {
    return this.showSignInCustomContentModalSubject$.asObservable();
  }

  /**
   * Showing Custom intro text in sign-in modal
   * @param customLabel - custom label for Intro Text
   */
  public showCustomIntroTxtInModal(customLabel: string): void {
    this.showSignInCustomIntroModalSubject$.next({
      customLabel,
      showMsg: true,
    });
  }

  /**
   * Showing custom "body" content in sign-in modal
   * @param customLabel - custom label for "body" content Text
   */
  public showCustomContentModalModal(customLabel: string): void {
    this.showSignInCustomContentModalSubject$.next(customLabel);
  }

  /**
   * hide Favorite Fund Message In Modal
   */
  public hideCustomIntroTxtInModal(): void {
    this.showSignInCustomIntroModalSubject$.next({
      customLabel: '',
      showMsg: false,
    });
  }

  /**
   * Change SignIn Modal Subject to show modal
   */
  public showSignInModal(): void {
    this.showSignInModalSubject$.next(true);
  }

  /**
   * set the personalised title for auth0 modal
   */
  public setAuth0SignInTitle(modalTitleKey?: string): void {
    const title = modalTitleKey
      ? this.translateService.instant(modalTitleKey)
      : this.translateService.instant('signin.signIn');
    this.signInModalTitle$.next(title);
  }

  public getAuth0SignInModalTitle$(): Observable<string> {
    return this.signInModalTitle$.asObservable();
  }

  /**
   * Change SignIn Modal Subject to hide modal
   */
  public hideSignInModal(): void {
    this.showSignInModalSubject$.next(false);
  }

  /**
   * Get component labels and required parameters
   * @param componentParams - parameters form component
   */
  public getSignInComponentFieldsInfo(
    componentParams: SignInInterface
  ): SignInComponentFieldsInfo {
    const showLoginForm =
      componentParams.overrideHideLoginForm || !this.siteConfig.hideLoginForm();
    const myfundsRole =
      this.segmentService.getCurrentSegmentId() === SegmentId.INVESTOR
        ? 'investor'
        : 'advisor';

    let showLegacyContent = true;
    if (componentParams.showLegacyContent !== undefined) {
      showLegacyContent = componentParams.showLegacyContent;
    }

    // bodyContent
    let bodyContent: string;
    if (componentParams && componentParams?.signInBodyContent) {
      bodyContent = componentParams.signInBodyContent;
    } else {
      if (
        this.segmentService.getCurrentSegmentId() ===
        SegmentId.FINANCIAL_PROFESSIONALS
      ) {
        bodyContent = this.translateService.instant(
          'signin.bodyContent-advisor'
        );
      } else {
        bodyContent = this.translateService.instant('signin.bodyContent');
      }
    }

    bodyContent = bodyContent.replace('$MYFUNDS[role]', myfundsRole);

    // Below code will replace $REFURL with current page url in base64 format
    bodyContent = bodyContent.replace('$REFURL', this.getRedirectURL('', true));

    const info: SignInComponentFieldsInfo = {
      // Title labels
      title:
        componentParams.signInTitle ||
        this.translateService.instant('signin.title'),
      subTitle:
        componentParams.subTitle ||
        this.translateService.instant('signin.subTitle'),
      // Button label
      ctaText: this.translateService.instant('signin.ctaText'),
      // bodyContent
      bodyContent,
    };
    const loginMaintenanceSettings = this.siteConfig.getLoginMaintenanceSettings();
    if (loginMaintenanceSettings?.showNotice) {
      info.infoText =
        loginMaintenanceSettings?.noticeText ||
        this.translateService.instant('signin.defaultNoticeText');
    }

    if (showLoginForm) {
      // User labels
      const userName = {
        label: this.translateService.instant('signin.userName-label'),
        placeHolder: this.translateService.instant(
          'signin.userName-placeHolder'
        ),
        helpText: this.translateService.instant('signin.userName-invalidText'),
        invalidText: this.translateService.instant(
          'signin.userName-invalidText'
        ),
      };
      info.userName = userName;

      // forgotIdLink params
      const forgotIdLink = {
        title: this.translateService.instant('signin.forgotIdLink-title'),
        url: this.translateService
          .instant('signin.forgotIdLink-url')
          ?.replace('$MYFUNDS[role]', myfundsRole),
      };
      info.forgotIdLink = forgotIdLink;
    }

    if (showLoginForm) {
      // Password labels
      const password = {
        label: this.translateService.instant('signin.password-label'),
        placeHolder: this.translateService.instant(
          'signin.password-placeHolder'
        ),
        helpText: this.translateService.instant('signin.password-invalidText'),
        invalidText: this.translateService.instant(
          'signin.password-invalidText'
        ),
      };
      info.password = password;

      // forgotPasswordLink params
      const forgotPasswordLink = {
        title: this.translateService.instant('signin.forgotPasswordLink-title'),
        url: this.translateService
          .instant('signin.forgotPasswordLink-url')
          ?.replace('$MYFUNDS[role]', myfundsRole),
      };
      info.forgotPasswordLink = forgotPasswordLink;
    }

    // Or Text params
    if (info.forgotIdLink && info.forgotPasswordLink) {
      info.orText = this.translateService.instant('signin.orText');
    }

    // accountAccessLink params
    if (showLegacyContent) {
      // account link is a part of LM legacy content
      const accountAccessLink = {
        title: this.translateService.instant('signin.accountAccessLink-title'),
        url: this.translateService.instant('signin.accountAccessLink'),
      };
      info.accountAccessLink = accountAccessLink;

      // legacyContent
      const legacyContent =
        componentParams.signInLegacyContent !== '' &&
        componentParams.signInLegacyContent
          ? componentParams.signInLegacyContent
          : this.translateService.instant('signin.legacyContent');
      info.legacyContent = legacyContent;
    }
    if (this.siteConfig.shouldShowAdditionalInfoOnSignInForm()) {
      info.additionalInformationText = this.translateService.instant(
        'signin.additionalInformation'
      );
    }

    // fastTrackRegistrationButtonLabel
    if (componentParams?.fastTrackRegistrationButtonLabel) {
      info.fastTrackRegistrationButtonLabel =
        componentParams?.fastTrackRegistrationButtonLabel;
    }

    // Additional Legacy options
    info.additionalLegacy = this.getAdditionalLegacy('signin.additionalLegacy');
    info.additionalLegacyBtns = this.getAdditionalLegacy(
      'signin.additionalLegacyBtn'
    );
    return info;
  }

  /**
   * Get Data for Fast Track Registration
   * @param signIn - SignInInterface Object
   * @param isHeader - determine usage in header component
   */
  public getFastTrackRegistration$(
    signIn: SignInInterface,
    isHeader: boolean = false
  ): Observable<SignInInterface> {
    // Fast Track Registration is available only for US
    if (this.appStateService.getChannel() !== 'en-us') {
      return of(signIn);
    }
    return this.personalisationService.isProfileActivated$().pipe(
      switchMap(
        (isProfileActivated: boolean): Observable<SignInInterface> => {
          if (!isProfileActivated) {
            signIn.signInBodyContent =
              (isHeader
                ? this.translateService.instant(
                    'signin.fastTrackRegistrationContentSimply'
                  )
                : this.translateService.instant(
                    'signin.fastTrackRegistrationContent'
                  )) || ' ';
            signIn.subTitle = this.translateService.instant(
              'signin.fastTrackRegistrationTitle'
            );
            signIn.fastTrackRegistrationButtonLabel = this.translateService.instant(
              'signin.fastTrackRegistrationButton'
            );
          }
          return of(signIn);
        }
      ),
      defaultIfEmpty(signIn)
    );
  }

  /**
   * Check sign-in form
   * @param signIn - SignInEvent
   * @param signInForm - FormGroup
   * @param signInComponentContent - SignInComponentFieldsInfo
   */
  public isSignInFormValid(
    signIn: SignInEvent,
    signInForm: FormGroup,
    signInComponentContent: SignInComponentFieldsInfo
  ): {
    signInForm: FormGroup;
    signInComponentContent: SignInComponentFieldsInfo;
    canSubmit: boolean;
  } {
    let canSubmit = false;
    signInForm.value.userId = signIn.userName;
    signInForm.value.password = signIn.password;
    // Commented now according to NGC-8586
    // this.signInForm.value.rememberMe = signIn.rememberMe ? REMEMBER_ME_YES : REMEMBER_ME_NO;
    if (!signIn.userName && !signIn.password) {
      signInComponentContent.userName.invalid = true;
      signInComponentContent.password.invalid = true;
    } else if (!signIn.userName && signIn.password) {
      signInComponentContent.userName.invalid = true;
      signInComponentContent.password.invalid = false;
    } else if (signIn.userName && !signIn.password) {
      signInComponentContent.userName.invalid = false;
      signInComponentContent.password.invalid = true;
    } else {
      signInComponentContent.userName.invalid = false;
      signInComponentContent.password.invalid = false;
      canSubmit = true;
    }
    return { canSubmit, signInForm, signInComponentContent };
  }

  /**
   * create cookie string form auth response data
   * @param response - response data from auth api
   * @returns - cookie string value
   */
  private createResponseCookieString(response: AuthResponse): string {
    if (response) {
      let cookieString = '';
      const keys = [
        'userId',
        'userSysNo',
        'userGroup',
        'emailAddress',
        'deviceRecognizedYN',
        'securityQuestionsFlag',
        'targetUrl',
        'redirectiveUrl',
      ];
      Object.entries(response).forEach(([key, value]) => {
        if (keys.includes(key)) {
          if (key === 'targetUrl') {
            value = this.removeParamsFromUrl(value);
          }
          cookieString += key + '=' + value + '&';
        }
      });
      return cookieString.substring(0, cookieString.length - 1);
    }
  }

  /**
   * Redirect to MFA pags along with required cookies on profile pages
   * @param response - response data from auth api
   */
  private redirectToMFA(response: AuthResponse): void {
    // Delete auth response cookie before reset values
    this.storageService.removeAuthResponseCookie();

    // Set TEMP_USER_COOKIE for profile dashboard
    this.storageService.store(TEMP_USER_COOKIE, 'Y', false, TEMP_USER_COOKIE);

    // Set MFA Complete Flag
    this.storageService.store(IS_MFA_COMPLETE, 'N', false, IS_MFA_COMPLETE);

    // set response body into cookie for profile dashboard
    this.storageService.store(
      AUTH_RESPONSE,
      this.createResponseCookieString(response),
      false,
      AUTH_RESPONSE
    );
    logger.debug('Redirecting to passcode', response.userSysNo);
    this.navService.navigateByUrl(
      `${this.appStateService.getProfileDomain()}/profile/signin/passCode`,
      '_self'
    );
  }

  /**
   * Removes defined parameters from URL
   * @param url - URL string
   */
  private removeParamsFromUrl(url: string): string {
    // Remove params from URL during login procedure
    const paramsToRemove = ['role', 'bypass', 'ft_token', 'mkt_tok', 'rc'];
    let reloadUrl: string = url;
    paramsToRemove.forEach((param: string) => {
      if (reloadUrl.includes(param)) {
        reloadUrl = removeParam(reloadUrl, param);
      }
    });
    return reloadUrl;
  }

  /**
   * Returns redirect URL hash for Profile site.
   * @param url string from API response
   * @param onlyRef boolean check if only ref url is required
   * @returns final URL with current page URL in refUrl parameter
   */
  public getRedirectURL(url: string, onlyRef: boolean): string {
    const urlHash = btoa(this.locationRef.href);
    if (onlyRef) {
      logger.debug('Redirect regRefUrl:', urlHash);
      return `regRefUrl=${urlHash}`;
    }
    logger.debug('Redirect url + refUrl:', url, urlHash);
    return `${url}?refUrl=${urlHash}`;
  }

  /**
   * Open Legacy Link
   * @param linkEvent - Additional Legacy Link event object
   */
  public openLegacyLink(linkEvent: AdditionalLegacyLink): void {
    logger.debug(linkEvent);
    const windowTarget = linkEvent?.isNewWindow ? '_blank' : '_self';
    if (linkEvent?.isExternal) {
      const trackConf: DialogConfig = {
        link_text: linkEvent.title,
        link_url: linkEvent.url,
        successUrl: linkEvent.url,
        targetWindow: windowTarget,
      };
      this.dialogService.open('external-link', trackConf);
    } else {
      this.windowRef.open(linkEvent.url, windowTarget);
    }
  }

  /**
   * Get Additional Legacy
   * @param additionalLegacyPrefix - labels prefix string
   * @returns - AdditionalLegacy objects array
   */
  private getAdditionalLegacy(
    additionalLegacyPrefix: string
  ): AdditionalLegacy[] {
    const additionalLegacy = this.translateService.getPrefixedLabelsArray(
      additionalLegacyPrefix
    );
    if (additionalLegacy.length > 0) {
      return additionalLegacy.map(
        (legacy: { [key: string]: string }): AdditionalLegacy => {
          return {
            legacyContent: legacy.content + ' ',
            legacyLink: {
              title: legacy['link-title'],
              url: this.replaceRoleToken(legacy['link-url']),
              isExternal: legacy['link-is-external']?.toLowerCase() === 'true',
              isNewWindow:
                legacy['link-is-new-window']?.toLowerCase() === 'true',
            },
          };
        }
      );
    }
    return [];
  }

  /**
   * Replace Role Token
   * @param stringWithToken - String With Token
   * @returns - string with replaced token
   */
  private replaceRoleToken(stringWithToken: string): string {
    const tokenReplacer =
      this.segmentService.getCurrentSegmentId() === SegmentId.INVESTOR
        ? this.translateService.instant('signin.roleTokenReplacer-investor')
        : this.translateService.instant('signin.roleTokenReplacer-advisor');
    return stringWithToken.replace('$USERROLE', tokenReplacer);
  }

  /**
   * Set Static Password Access Config
   * @param staticPassAccessConfig - Static Password Access Config
   */
  public setStaticPassAccessConfig(
    staticPassAccessConfig: StaticPassAccessConfig
  ): void {
    this.staticPassAccessConfig$.next(staticPassAccessConfig);
  }

  /**
   * Get Static Password Access Config
   * @returns - StaticPassAccessConfig observable
   */
  public getStaticPassAccessConfig$(): Observable<StaticPassAccessConfig> {
    return this.staticPassAccessConfig$.asObservable();
  }

  /**
   * Retorn Pasword status
   * @param staticPassAccessUsers - StaticPassAccessUser array
   * @param hashedKey -Hashed Key string,
   * @returns - boolean
   */
  public isStaticPasswordCorrect(
    staticPassAccessUsers: StaticPassAccessUser[],
    hashedKey: string | Int32Array
  ): boolean {
    return staticPassAccessUsers.some(
      (restriction: StaticPassAccessUser) =>
        restriction.hashedUserAccess === hashedKey
    );
  }
}
