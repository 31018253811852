import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { InsightsService } from '@marketing/insights/insights.service';
import { AnalyticsService, PaginationComponent } from '@frk/eds-components';
import { Logger } from '@utils/logger';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { takeUntil } from 'rxjs/operators';
import { FtSearchService } from '@search/services/ftsearch.service';
import { Subject } from 'rxjs';
import { FtInsightsItem } from '@search/interfaces/insights.interface';
import { FtSearchOptions } from '@search/interfaces/search.interface';
import {
  AppStateService,
  DebugService,
  GlobalConfigService,
  SegmentService,
  WidenService,
  WindowScrollService,
} from '@services';
import { ConfigService } from '@search/services/config.service';
import { PaginationActionEnum } from '@types';
import { FiltersService } from '@search/services/filters.service';
import { Title } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';

const logger = Logger.getLogger('InsightsLibraryComponent');

interface DisplayOptionsPr {
  title: string;
  preTitle: string;
  document: string;
  pressReleaseCategoryOne: string;
  pressReleaseCategoryTwo: string;
  pressReleaseCategoryThree: string;
  pressReleaseCategoryFour: string;
  pressReleaseCategoryFive: string;
  pressReleaseCategorySix: string;
  pressReleaseCategorySeven: string;
  pressReleaseCategoryEight: string;
  pressReleaseCategoryNine: string;
  pressReleaseCategoryTen: string;
  resourceBundles: string;
  isPreFiltered: boolean;
}

@Component({
  selector: 'ft-press-releases',
  templateUrl: './press-releases.component.html',
  providers: [FtSearchService],
})
export class PressReleasesComponent
  extends AbstractBaseComponent
  implements OnInit, OnDestroy {
  public isDebugMode = false;
  public sideBarDocumentRef: any;
  public visibleRecords: number;
  public totalRecords: number;
  public translationLabel: any;
  public aggregations = {};
  public simLogoResourceBundle: object;
  public totalPages = 0;
  public results: FtInsightsItem[];
  public options: FtSearchOptions;
  @ViewChild(PaginationComponent, { static: false })
  paginationComponent: PaginationComponent;

  private unsubscribe$: Subject<void> = new Subject<void>();
  private activeFilters: string[];
  private displayOptions: DisplayOptionsPr;
  private query = '*';
  private searchTerm = '';
  private pressReleaseFields = [
    'pressReleaseCategoryOne',
    'pressReleaseCategoryTwo',
    'pressReleaseCategoryThree',
    'pressReleaseCategoryFour',
    'pressReleaseCategoryFive',
    'pressReleaseCategorySix',
    'pressReleaseCategorySeven',
    'pressReleaseCategoryEight',
    'pressReleaseCategoryNine',
    'pressReleaseCategoryTen',
  ];
  pageTitle: string;

  // prettier-ignore
  constructor( // NOSONAR - typescript:S107 - we need to accept more than 7 parameters in the constructor.
    private analytics: AnalyticsService,
    private appStateService: AppStateService,
    private changeDetector: ChangeDetectorRef,
    private configService: ConfigService,
    private debugService: DebugService,
    private filters: FiltersService,
    private insightsService: InsightsService,
    private pageConfigService: GlobalConfigService,
    private scrollService: WindowScrollService,
    private searchService: FtSearchService,
    private segmentService: SegmentService,
    private titleService: Title,
    private widenService: WidenService
  ) {
    super();
    this.searchService.insightsOutput$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ({ results }) => {
          logger.debug(results);
          this.results = results.response?.hits?.hits;
          this.results.forEach((element) => {
            if (element._source?.referenceDate) {
              element._source.referenceDate = this.insightsService.getLongDate(
                element._source.referenceDate
              );
            }
            if (element._source?.image) {
              element._source.imgSrc = this.getImageUrl(element._source.image);
            }

            if (element._source?.logo) {
              element._source.SIM = this.insightsService.getSIMLogo(
                element._source.logo,
                true,
                this.simLogoResourceBundle
              );
            }

            element._source.relativePath = this.configService.getLocalizedPath(
              element._source.relativePath
            );
          });
          this.aggregations = results.response.aggregations;
          this.visibleRecords = this.results.length;
          this.totalRecords = results.response?.hits?.total?.value;
          this.totalPages = Math.ceil(
            results.response?.hits?.total?.value / this.options.counters.pages
          );
          this.paginationComponent?.updatePagination(this.totalPages);
          this.changeDetector.detectChanges();
        },
        (error) => {
          logger.debug('Subscription Error', error);
        }
      );
  }

  ngOnInit(): void {
    this.translationLabel = this.getTranslationLabels();
    this.activeFilters = this.filters.activeFilters;
    this.displayOptions = this.component?.getParameters();
    this.pageTitle = this.titleService.getTitle();
    this.setDefaultOptions();
    this.query = this.generateQuery();
    if (this.component?.getModels()?.document) {
      this.sideBarDocumentRef = this.page
        .getContent(this.component?.getModels()?.document)
        ?.getData();
    }

    this.configService
      .getConfig('insights')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((config) => {
        const insightAudiences = this.segmentService.getCurrentSegment()
          ?.insightAudiences;
        config.httpParams = config.httpParams.set(
          'exclude',
          insightAudiences ? insightAudiences : ''
        );
        this.options.config = config;
        this.setPreFilter();
        this.termSearch(this.query);
      });

    this.debugService
      .isShowInsightsReport$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isShowInsightsReport: boolean) => {
        this.isDebugMode = isShowInsightsReport;
        this.changeDetector.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Pagination
   * @param pageEvent - click event
   */
  public goToPage(pageEvent): boolean {
    if (pageEvent.page && pageEvent.action !== 'load') {
      this.options.page = (pageEvent.page - 1) * this.options.counters.pages;
      const searchParams = {
        options: this.options,
        filters: this.filters.getFilters(),
      };
      this.searchService.getInsights(searchParams);
      this.scrollService.scrollToAnchor('insightScrollPosition');
      return true;
    }
  }

  /**
   * Returns component labels
   */
  private getTranslationLabels(): any {
    logger.debug(this.component?.getModels());
    const resourceBundle: string = this.component.getParameters()
      ?.resourceBundles;
    return this.component?.getModels()[resourceBundle];
  }

  /**
   * Gets widen image URL
   * @param widenObject - Object
   */
  private getImageUrl(widenObject: any): string {
    if (widenObject) {
      return this.widenService.getWidenImageVariantUrl(
        JSON.parse(widenObject).url,
        'original',
        'webp',
        'eds-card-article'
      );
    }
  }

  /**
   * Sets pre-filters for search.
   */
  private setPreFilter(): void {
    this.filters.activeFilters = [];
    const filters: DisplayOptionsPr = this.displayOptions;
    if (filters) {
      // KEYS is an array with component params defined for pre-filter
      // TODO: to set correct fields for pre-filter query
      const keys = [];
      Object.entries(filters).forEach(([key, value]) => {
        if (keys.includes(key)) {
          if (value) {
            const arr = [];
            arr.push({ value });
            if (this.displayOptions) {
              this.displayOptions.isPreFiltered = true;
            }
            this.filters.addFilter(key.toLowerCase() + '.exact', arr);
          }
        }
      });
    }
  }

  /**
   * Execute search query for insights
   * @param query - string
   */
  private termSearch(query: string): boolean {
    this.options.articleType = 'press-release';
    if (query.length > 3) {
      this.options.term = query;
      this.searchTerm = query;
    }
    const searchParams = {
      options: this.options,
      filters: this.filters.getFilters(),
    };
    logger.debug('searchParams:', searchParams);
    this.searchService.getInsights(searchParams);
    return true;
  }

  /**
   * Sets default FtSearchOptions values
   */
  private setDefaultOptions(): void {
    this.options = {
      page: 0,
      term: '*',
      dataSource: 'dev',
      articleType: 'insight',
      current: '',
      config: {
        ftSearchUrl: '',
        ftInsightsUrl: '',
        httpParams: new HttpParams(),
        httpOptions: {},
      },
      counters: {
        funds: 4,
        literature: 4,
        pages: 24,
      },
    };
  }

  /**
   * Get Taxonomy Query with Taxonomy Language from site configuration
   */
  private getTaxonomyQuery(): string {
    const taxonomyLanguage = this.pageConfigService.config.siteConfiguration
      .site.taxonomyLanguage;
    return `(taxonomyClassification:${taxonomyLanguage}) OR (!taxonomyClassification:*)`;
  }

  /**
   * Generates search query from displayOptions
   */
  private generateQuery(): string {
    const generatedQuery: string = this.pressReleaseFields
      .map((pressReleaseField: string) => {
        return this.displayOptions[pressReleaseField];
      })
      .filter((option: string) => {
        return !!option;
      })
      .map((filteredOption: string) => {
        return `documentPath:${filteredOption} OR pressReleaseCategory:${filteredOption}*`;
      })
      .join(' OR ');
    if (!generatedQuery) {
      return 'documentPath:news-room OR pressReleaseCategory:news-room*';
    }
    return `(${generatedQuery}) AND (${this.getTaxonomyQuery()})`;
  }
}
